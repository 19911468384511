import { Component, OnInit } from '@angular/core';
import {ParutionService} from '../../../../../service/interne/parution/parutionService';
import {FichiersParutionService} from '../../../../../service/interne/parution/fichiersParutionService';
import {Parution} from '../../../../../model/parution/Parution';
import {FichiersParution} from '../../../../../model/parution/FichiersParution';
import {ActivatedRoute} from '@angular/router';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {Environement} from '../../../../../environement/environement';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-escal-parution-fiche-multimedia',
  templateUrl: './escal-parution-fiche-multimedia.component.html',
  styleUrls: ['./escal-parution-fiche-multimedia.component.scss'],
  providers: [ ParutionService, FichiersParutionService ]
})
export class EscalParutionFicheMultimediaComponent implements OnInit {

  fichier = '';
  fichierTelecharge: Array<any> = [];
  fichiers: Array<FichiersParution>;
  parution: Parution;
  allFichiersParution: Array<FichiersParution>;
  pret: number;
  private sub: any;
  constructor(private parutionService: ParutionService, private fichiersParutionService: FichiersParutionService,
              private route: ActivatedRoute, private dialogueShareService: DialogueShareService,
              private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.fichiers = [];
    this.pret = 0;
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      this.parutionService.getParutionDetails(params['id'])
        .then((retour) => {
          that.parution = retour as Parution;
          that.parution.fichiersParutions.forEach((fichier) => {
            if (fichier.multimedia === true) {
              this.fichiers.push(fichier);
            }
          });
        });
      this.fichiersParutionService.getAllFichiersParution()
        .then((fichiers) => {
          that.allFichiersParution = fichiers['hydra:member'] as Array<FichiersParution>;
        });
    });
  }

  changeDocument(event) {
    this.pret = 1;
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fichier = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.fichierTelecharge['nom'] = this.sansAccent(file.name).replace(' ', '');
        that.fichierTelecharge['nom'] = that.fichierTelecharge['nom'].replace('’', '');
        that.fichierTelecharge['nom'] = that.fichierTelecharge['nom'].split(' ').join('');
        // Eviter les chevauchements des noms, urls et dates
        if (that.fichierTelecharge['nom'].length > 35 && that.fichierTelecharge['nom'].includes('-') === false) {
          let secondMoitieChaine;
          secondMoitieChaine = that.fichierTelecharge['nom'].substring(34);
          // Saut de ligne seulement si le nom comporte plus de 43 caractères
          if (secondMoitieChaine.length > 8) {
            let premierMoitieChaine;
            premierMoitieChaine = that.fichierTelecharge['nom'].substring(0, 34);
            that.fichierTelecharge['nom'] = premierMoitieChaine + '-' + secondMoitieChaine;
          }
        }
        that.fichierTelecharge['format'] = file.type;
        that.fichierTelecharge['size'] = file.size;
        if (typeof reader.result === 'string') {
          that.fichierTelecharge['value'] = reader.result.split(',')[1];
        }
        this.pret = 2;
      };
    }
  }

  sansAccent(chaine) {
    const accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
    ];
    const noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
    for (let i = 0; i < accent.length; i++) {
      chaine = chaine.replace(accent[i], noaccent[i]);
    }
    return chaine;
  }

  checkDocument() {
    this.dialogueShareService.addDialogue('Veuillez ajouter un fichier');
  }

  pretDocument() {
    this.dialogueShareService.addDialogue('Le fichier est en préparation');
  }

  saveDocument() {
    const that = this;
    let existe = false;
    // Quand il n'y a pas de fichier
    if (this.fichierTelecharge['nom'] == undefined) {
      this.pret = 0;
      this.dialogueShareService.addDialogue('Veuillez ajouter un fichier');
    } else if (this.fichierTelecharge['size'] >= 330000000) {
      this.pret = 0;
      this.dialogueShareService.addDialogue('Le fichier est trop volumineux');
    } else {
      this.spinner.show();
      // pré-enregistrement du lien
      this.fichierTelecharge['url'] = Environement.PARUTIONS_URL + '/multimedia/' + this.fichierTelecharge['nom'];
      // Vérifier si le nom du fichier existe déjà et que le type soit différent
      if (this.parution.fichiersParutions.length > 0) {
        this.allFichiersParution.forEach(doc => {
          // si un fichier pdf a déjà ce nom là
          if (doc.nom == this.fichierTelecharge['nom']) {
            this.spinner.hide();
            this.pret = 0;
            this.dialogueShareService.addDialogue('Un fichier du même nom existe déjà');
            existe = true;
          }
        });
        // Vérifier la longueur du nom du fichier
        if (this.fichierTelecharge['nom'].length > 70) {
          this.spinner.hide();
          this.pret = 0;
          this.dialogueShareService.addDialogue('Le nom du fichier est trop long');
          existe = true;
        }
        // Ajouter le fichier seulement si le nom n'existe pas et que la longueur est respecté
        if (existe === false) {
          this.fichiersParutionService.ajouterDocument(this.parution.id, this.fichierTelecharge, this.fichierTelecharge['format'], true)
            .then(() => {
              that.ngOnInit();
              this.spinner.hide();
              this.pret = 0;
              this.dialogueShareService.addDialogue('Le fichier est bien enregistré');
            });
        }
      } else {
        if (this.fichierTelecharge['nom'].length > 70) {
          this.spinner.hide();
          this.pret = 0;
          this.dialogueShareService.addDialogue('Le nom du fichier est trop long');
        } else {
          this.fichiersParutionService.ajouterDocument(this.parution.id, this.fichierTelecharge, this.fichierTelecharge['format'], true)
            .then(() => {
              that.ngOnInit();
              this.spinner.hide();
              this.pret = 0;
              this.dialogueShareService.addDialogue('Le fichier est bien enregistré');
            });
        }
      }
    }
  }


  deleteFichier(fichierId) {
    const that = this;
    this.dialogueShareService.addDialogue('êtes-vous sûr de vouloir supprimer ce fichier', function () {
      that.fichiersParutionService.supprimerFichier(fichierId)
        .then(() => {
          that.dialogueShareService.addDialogue('Le fichier est bien supprimé');
          that.ngOnInit();
        })
        .catch(() => {
          that.dialogueShareService.addDialogue('Erreur de supression');
        });
    });
  }

}
