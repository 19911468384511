import { Component, OnInit } from '@angular/core';
import {Parution} from '../../../../../model/parution/Parution';
import {ParutionService} from '../../../../../service/interne/parution/parutionService';
import {ActivatedRoute} from '@angular/router';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {Environement} from '../../../../../environement/environement';
import {FichiersParutionService} from '../../../../../service/interne/parution/fichiersParutionService';
import {FichiersParution} from '../../../../../model/parution/FichiersParution';
import {SupportService} from '../../../../../service/interne/support/SupportService';
import {Support} from '../../../../../model/support/Support';
import {LogoSupportService} from '../../../../../service/interne/support/logoSupportService';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-escal-parution-fiche-fichier',
  templateUrl: './escal-parution-fiche-fichier.component.html',
  styleUrls: ['./escal-parution-fiche-fichier.component.scss'],
  providers: [ ParutionService, FichiersParutionService, SupportService, LogoSupportService ]
})
export class EscalParutionFicheFichierComponent implements OnInit {

  fichier = '';
  fichierTelecharge: Array<any> = [];
  parution: Parution;
  fichiers: Array<FichiersParution>;
  multimedias: Array<FichiersParution>;
  allFichiersParution: Array<FichiersParution>;
  private sub: any;
  logoSupport: boolean;
  support: Support;
  baseServeur =  Environement.BASE_API_URL;
  photoUpload: Array<any> = [];
  constructor(private parutionService: ParutionService, private fichiersParutionService: FichiersParutionService,
              private route: ActivatedRoute, private dialogueShareService: DialogueShareService, private supportService: SupportService,
              private logoSupportService: LogoSupportService, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.logoSupport = true;
    this.fichiers = [];
    this.multimedias = [];
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      this.parutionService.getParutionDetails(params['id'])
        .then((retour) => {
          that.parution = retour as Parution;
          that.parution.fichiersParutions.forEach((fichier) => {
            if (fichier.multimedia == false) {
              this.fichiers.push(fichier);
            }
            if (fichier.multimedia == true) {
              this.multimedias.push(fichier);
            }
          });
          // verifier si un logo du support existe sur escalnet ou intranet
          this.supportService.getSupportDetails(that.parution.support.id).then((support) => {
            if (support.logoSupport == null) {
              // si le support n'a pas de logo sur escalnet, on verifie sur intranet
              this.parutionService.getLogoSupportIntranet(that.parution.support.ancienId).then((suppIntra) => {
                if (suppIntra.logo == '' || suppIntra.logo == null || suppIntra.logo.substr(-4, 4) == '.svg') {
                  this.logoSupport = false;
                  this.support = support;
                }
              });
            }
          });
        });
      this.fichiersParutionService.getAllFichiersParution()
        .then((fichiers) => {
          that.allFichiersParution = fichiers['hydra:member'] as Array<FichiersParution>;
        });
    });
  }

  changeDocument(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fichier = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        const year = this.parution.date.toString().substr(2, 2);
        const month = this.parution.date.toString().substr(5, 2);
        const day = this.parution.date.toString().substr(8, 2);
        const date = day + month + year;
        that.fichierTelecharge['nom'] = date + '-' + this.parution.id + '.pdf';
        /*that.fichierTelecharge['nom'] = this.sansAccent(file.name).replace(' ', '');
        that.fichierTelecharge['nom'] = that.fichierTelecharge['nom'].replace('’', '');
        that.fichierTelecharge['nom'] = that.fichierTelecharge['nom'].split(' ').join('');
        // Eviter les chevauchements des noms, urls et dates
        if (that.fichierTelecharge['nom'].length > 35 && that.fichierTelecharge['nom'].includes('-') === false) {
          let secondMoitieChaine;
          secondMoitieChaine = that.fichierTelecharge['nom'].substring(34);
          // Saut de ligne seulement si le nom comporte plus de 43 caractères
          if (secondMoitieChaine.length > 8) {
            let premierMoitieChaine;
            premierMoitieChaine = that.fichierTelecharge['nom'].substring(0, 34);
            that.fichierTelecharge['nom'] = premierMoitieChaine + '-' + secondMoitieChaine;
          }
        }*/
        that.fichierTelecharge['format'] = file.type;
        if (typeof reader.result === 'string') {
          that.fichierTelecharge['value'] = reader.result.split(',')[1];
        }
      };
    }
  }

  sansAccent(chaine) {
    const accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
    ];
    const noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
    for (let i = 0; i < accent.length; i++) {
      chaine = chaine.replace(accent[i], noaccent[i]);
    }
    return chaine;
  }

  saveDocument() {
    const that = this;
    let existe = false;
    // Quand il n'y a pas de fichier
    if (this.fichierTelecharge['nom'] == undefined) {
      this.dialogueShareService.addDialogue('Veuillez ajouter un fichier');
    } else {
      // pré-enregistrement du lien
      this.fichierTelecharge['url'] = Environement.PARUTIONS_URL + '/file/' + this.fichierTelecharge['nom'];
      // Vérifier si le nom du fichier existe déjà et que le type soit différent
      if (this.parution.fichiersParutions.length > 0) {
        this.allFichiersParution.forEach(doc => {
          // si un fichier pdf a déjà ce nom là
          if (doc.nom == this.fichierTelecharge['nom']) {
            this.dialogueShareService.addDialogue('Un fichier du même nom existe déjà');
            existe = true;
          }
        });
        // Vérifier la longueur du nom du fichier
        if (this.fichierTelecharge['nom'].length > 70) {
          this.dialogueShareService.addDialogue('Le nom du fichier est trop long');
          existe = true;
        }
        // Ajouter le fichier seulement si le nom n'existe pas et que la longueur est respecté
        if (existe === false) {
          this.fichiersParutionService.ajouterDocument(this.parution.id, this.fichierTelecharge, 'pdf', false)
            .then(() => {
              that.ngOnInit();
              this.dialogueShareService.addDialogue('Le fichier est bien enregistré');
            });
        }
      } else {
        if (this.fichierTelecharge['nom'].length > 70) {
          this.dialogueShareService.addDialogue('Le nom du fichier est trop long');
        } else {
          this.fichiersParutionService.ajouterDocument(this.parution.id, this.fichierTelecharge, 'pdf', false)
            .then(() => {
              that.ngOnInit();
              this.dialogueShareService.addDialogue('Le fichier est bien enregistré');
            });
        }
      }
    }
  }

  deleteFichier(fichierId) {
    const that = this;
    this.dialogueShareService.addDialogue('êtes-vous sûr de vouloir supprimer ce fichier', function () {
      that.fichiersParutionService.supprimerFichier(fichierId)
        .then(() => {
          that.dialogueShareService.addDialogue('Le fichier est bien supprimé');
          that.ngOnInit();
        })
        .catch(() => {
          that.dialogueShareService.addDialogue('Erreur de supression');
        });
    });
  }

  verifDocument() {
    if (this.fichiers.length > 0) {
      this.dialogueShareService.addDialogue('Un pdf est déjà attaché à la parution');
    }
  }

  genererDocument() {
    // si le support n'a pas de logo
    if (this.logoSupport == true) {
      // si un pdf n'est pas déjà attaché
      if (this.fichiers.length == 0) {
        // si la parution a au moins un fichier multimedia
        if (this.multimedias.length > 0) {
          this.spinner.show();
          this.parutionService.genererPdfParution(this.parution.id, '~', '~', this.parution.support.id,
            this.parution.nom, this.parution.date, '~', this.parution.ancienId)
            .then(() => {
              this.spinner.hide();
              this.ngOnInit();
              this.dialogueShareService.addDialogue('Le fichier a bien été généré');
            });
        } else if (this.parution.contenu != '') {
          // sinon si la parution a un contenu
          let url = '~';
          let lien = '~';
          if (this.parution.photoParution != null) {
            url = this.parution.photoParution.url;
          }
          if (this.parution.lien != '') {
            lien = this.parution.lien;
          }
          this.spinner.show();
          this.parutionService.genererPdfParution(this.parution.id, this.parution.contenu, lien, this.parution.support.id,
            this.parution.nom, this.parution.date, url, this.parution.ancienId)
            .then(() => {
              this.spinner.hide();
              this.ngOnInit();
              this.dialogueShareService.addDialogue('Le fichier a bien été généré');
            });
        } else {
          this.dialogueShareService.addDialogue('La parution n\'a pas de contenu à générer');
        }
      } else {
        this.dialogueShareService.addDialogue('Un pdf est déjà attaché à la parution');
      }
    } else {
      this.dialogueShareService.addDialogue('Le support n\'a pas de logo');
    }
  }

  changeLogo(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.photoUpload['filename'] = file.name;
        that.photoUpload['filetype'] = file.type;
        // @ts-ignore
        that.photoUpload['value'] = reader.result.split(',')[1];
        that.photoUpload['extension'] = file.name.split('.')[1];
      };
    }
  }

  saveLogo() {
    const that = this;
    this.logoSupportService.addLogo(this.support.id, this.photoUpload)
      .then((retour) => {
        that.ngOnInit();
        this.dialogueShareService.addDialogue('Le logo a bien été ajouté');
      });
  }

}
