import {Component, OnInit, ViewChild} from '@angular/core';
import {ParutionService} from '../../../../service/interne/parution/parutionService';
import {Parution} from '../../../../model/parution/Parution';
import {PaginationRechercheComponent} from '../../../utilitaire/pagination/pagination-recherche/pagination-recherche.component';

@Component({
  selector: 'app-escal-parution-list',
  templateUrl: './escal-parution-list.component.html',
  styleUrls: ['./escal-parution-list.component.scss'],
  providers: [ParutionService]
})
export class EscalParutionListComponent implements OnInit {

  typeRecherche: Array<any> = [];
  headerContent: Array<any> = [];
  recherche: Array<any> = [];
  parutions: Array<Parution>;
  curentPage = 1;
  maxPage: number;
  total: number;

  @ViewChild(PaginationRechercheComponent) parutionsPagination: PaginationRechercheComponent;
  constructor(private parutionService: ParutionService) { }

  ngOnInit() {
    this.initContent();
    this.headerContent.push('isParution');
    this.headerContent.push('Nom');
    this.headerContent.push('Client');
    this.headerContent.push('Support');
    this.headerContent.push('Date');

    this.typeRecherche.push('Tous');
    this.typeRecherche.push('Nom');
    this.typeRecherche.push('Client');
    this.typeRecherche.push('Support');
    this.typeRecherche.push('Résumé');
    this.typeRecherche.push('Auteur');
    this.typeRecherche.push('Intranet');
  }

  initContent() {
    const that = this;
    // affichage par defaut
    if ( this.recherche.length === 0) {
      if (!this.parutionsPagination.jours) {
        this.parutionService.getParutionsParDefaut(this.curentPage, 30)
          .then((data) => {
            that.parutions = data['hydra:member'] as Array<Parution>;
            that.total = data['hydra:totalItems'];
            that.getMaxPage(data['hydra:totalItems']);
          });
      } else {
        this.parutionService.getParutionsParDefaut(this.curentPage, this.parutionsPagination.jours)
          .then((data) => {
            that.parutions = data['hydra:member'] as Array<Parution>;
            that.total = data['hydra:totalItems'];
            that.getMaxPage(data['hydra:totalItems']);
          });
      }
    } else if (this.recherche[0].length >= 3 ) {
      if (this.recherche[0] == 'changeJours') {
        // si changement de jours sans contenu recherche
        this.parutionService.getParutionsParDefaut(this.curentPage, this.recherche[2])
          .then((data) => {
            that.parutions = data['hydra:member'] as Array<Parution>;
            that.total = data['hydra:totalItems'];
            that.getMaxPage(data['hydra:totalItems']);
          });
      } else {
        // si contenu recherche
        this.parutionService.getParutionsRecherche(this.recherche, this.curentPage)
          .then((data) => {
            that.parutions = data['hydra:member'] as Array<Parution>;
            that.total = data['hydra:totalItems'];
            that.getMaxPage(data['hydra:totalItems']);
          });
      }
    }
  }

  updateRecherche(recherche: Array<any>) {
    this.curentPage = 1;
    if (recherche[0] === '') {
      this.recherche = [];
    } else {
      this.recherche = recherche;
    }
    this.initContent();
  }

  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.initContent();
  }

}
