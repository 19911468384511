import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-pagination-content-parution-intranet',
  templateUrl: './pagination-content-parution-intranet.component.html',
  styleUrls: ['./pagination-content-parution-intranet.component.scss']
})
export class PaginationContentParutionIntranetComponent implements OnInit {

  @Input()
  parutions: Array<any>;
  constructor() { }

  ngOnInit() {
    this.parutions.forEach( societe => {
      societe.nom = societe.nom.replace('&amp;', '&');
      societe.nom = societe.nom.replace('&#039;', '’');
      societe.nom = societe.nom.replace('L&#039;', 'L’');
      societe.nom = societe.nom.replace('l&#039;', 'l’');
      societe.nom = societe.nom.replace(' &quot;', '"');
      societe.nom = societe.nom.replace('&quot;', '"');
  });
  }

}
