import {Parution} from './Parution';


export class DiversParution {
  id: number;
  parution: Parution;
  photo: boolean;
  illustration: boolean;
  logo: boolean;
  urlClient: boolean;
  agenda: boolean;
  graphique: boolean;
  tableau: boolean;
  nomination: boolean;
  courrierLecteur: boolean;
  mailing: boolean;
  mailCree: boolean;
  imprimer: boolean;
  imprimante: string;
  bestOfClient: boolean;
  bestOfGeneral: boolean;
  lienRS: string;
  paruFb: boolean;
  paruTw: boolean;
  paruFbEscal: boolean;
  paruTwEscal: boolean;
  pressroom: boolean;

  constructor(id: number, parution: Parution, photo: boolean, illustration: boolean, logo: boolean, urlClient: boolean,
              agenda: boolean, graphique: boolean, tableau: boolean, nomination: boolean, courrierLecteur: boolean,
              mailing: boolean, mailCree: boolean, imprimer: boolean, imprimante: string, bestOfClient: boolean, bestOfGeneral: boolean,
              lienRS: string, paruFb: boolean, paruTw: boolean, paruFbEscal: boolean, paruTwEscal: boolean, pressroom: boolean) {
    this.id = id;
    this.parution = parution;
    this.photo = photo;
    this.illustration = illustration;
    this.logo = logo;
    this.urlClient = urlClient;
    this.agenda = agenda;
    this.graphique = graphique;
    this.tableau = tableau;
    this.nomination = nomination;
    this.courrierLecteur = courrierLecteur;
    this.mailing = mailing;
    this.mailCree = mailCree;
    this.imprimer = imprimer;
    this.imprimante = imprimante;
    this.bestOfClient = bestOfClient;
    this.bestOfGeneral = bestOfGeneral;
    this.lienRS = lienRS;
    this.paruFb = paruFb;
    this.paruTw = paruTw;
    this.paruFbEscal = paruFbEscal;
    this.paruTwEscal = paruTwEscal;
    this.pressroom = pressroom;
  }
}
