import { Component, OnInit } from '@angular/core';

import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ThematiqueService} from '../../../../service/interne/veille/ThematiqueService';

import {Thematiques} from '../../../../model/veille/Thematiques';
import {AuthShareService} from "../../../../service/partage/AuthShareService";

@Component({
  selector: 'app-escal-veille-thematique',
  templateUrl: './escal-veille-thematique.component.html',
  styleUrls: ['./escal-veille-thematique.component.scss'],
  providers: [ ThematiqueService ]
})
export class EscalVeilleThematiqueComponent implements OnInit {

  thematiques: Array<Thematiques>;
  libelle: string;

  constructor(private thematiqueService: ThematiqueService, private dialogueShareService: DialogueShareService, public authUserShareService: AuthShareService) { }

  ngOnInit() {
    const that = this;
    this.thematiqueService.getThematiques()
      .then((retour) => {
        that.thematiques = retour as Array<Thematiques>;
        // trier par ordre alphabétique avec ou sans accent
        that.thematiques.sort(function compare(a, b) {
          if (a.libelle.localeCompare(b.libelle) < b.libelle.localeCompare(b.libelle, 'en', { sensitivity: 'base' })) {
            return -1;
          }
          if (a.libelle.localeCompare(b.libelle) > b.libelle.localeCompare(b.libelle, 'en', { sensitivity: 'base' })) {
            return 1;
          }
          return 0;
        });
      });
  }

  addThematique(){
    if(this.libelle.length < 3){
      this.dialogueShareService.addDialogue('le libelle doit contenir au moins 3 caractère');
    }
    else{
      const that = this;
      this.thematiqueService.addThematique(this.libelle)
        .then((retour) => {
          that.dialogueShareService.addDialogue('thématique ajoutée');
          this.ngOnInit();
        })
        .catch((retour) => {
          that.dialogueShareService.addDialogue('cette thématique existe déja');
        });
    }
  }
}
