import { Component, OnInit } from '@angular/core';
import {DialogueShareService} from '../../../service/partage/utilitaire/DialogueShareService';
import {FichiersCreateurLien} from '../../../model/utilitaire/FichiersCreateurLien';
import {FichiersCreateurLienService} from '../../../service/interne/utilitaire/FichiersCreateurLienService';
import {Environement} from '../../../environement/environement';

@Component({
  selector: 'app-utilitaire-liens',
  templateUrl: './utilitaire-liens.component.html',
  styleUrls: ['./utilitaire-liens.component.scss'],
  providers: [ FichiersCreateurLienService ]
})
export class UtilitaireLiensComponent implements OnInit {

  fichier = '';
  fichierTelecharge: Array<any> = [];
  fichiersLiens: Array<any>;
  fichiers: Array<any>;
  constructor(private dialogueShareService: DialogueShareService, private fichiersCreateurLienService: FichiersCreateurLienService) { }

  ngOnInit() {
    this.fichiersLiens = [];
    this.fichiers = [];
    this.fichiersCreateurLienService.getFichiersCreateurLien().then((fichiers) => {
      this.fichiers = fichiers['hydra:member'];
    });
  }

  changeDocument(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.fichier = file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.fichierTelecharge['nom'] = this.sansAccent(file.name).replace(' ', '');
        that.fichierTelecharge['nom'] = that.fichierTelecharge['nom'].replace('’', '');
        that.fichierTelecharge['nom'] = that.fichierTelecharge['nom'].split(' ').join('');
        // Eviter les chevauchements des noms, urls et dates
        if (that.fichierTelecharge['nom'].length > 35 && that.fichierTelecharge['nom'].includes('-') === false) {
          let secondMoitieChaine;
          secondMoitieChaine = that.fichierTelecharge['nom'].substring(34);
          // Saut de ligne seulement si le nom comporte plus de 43 caractère
          if (secondMoitieChaine.length > 8) {
            let premierMoitieChaine;
            premierMoitieChaine = that.fichierTelecharge['nom'].substring(0, 34);
            that.fichierTelecharge['nom'] = premierMoitieChaine + '-' + secondMoitieChaine;
          }
        }
        that.fichierTelecharge['format'] = file.type;
        // this.typeDuDocument(that.active);
        if (typeof reader.result === "string") {
          that.fichierTelecharge['value'] = reader.result.split(',')[1];
        }
      };
    }
  }

  sansAccent(chaine) {
    const accent = [
      /[\300-\306]/g, /[\340-\346]/g, // A, a
      /[\310-\313]/g, /[\350-\353]/g, // E, e
      /[\314-\317]/g, /[\354-\357]/g, // I, i
      /[\322-\330]/g, /[\362-\370]/g, // O, o
      /[\331-\334]/g, /[\371-\374]/g, // U, u
      /[\321]/g, /[\361]/g, // N, n
      /[\307]/g, /[\347]/g, // C, c
    ];
    const noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];
    for (let i = 0; i < accent.length; i++) {
      chaine = chaine.replace(accent[i], noaccent[i]);
    }
    return chaine;
  }

  saveDocument() {
    const that = this;
    let existe = false;
    // Quand il n'y a pas de fichier
    if (this.fichierTelecharge['nom'] == undefined) {
      this.dialogueShareService.addDialogue('Veuillez ajouter un fichier');
    } else {
      // pré-enregistrement du lien
      this.fichierTelecharge['url'] = Environement.DOCUMENTS_URL +
        '/liens/' + this.fichierTelecharge['nom'];
      // Vérifier si le nom du fichier existe déjà  et que le type soit différent
      this.fichiersCreateurLienService.getFichiersCreateurLien().then((documents) => {
        this.fichiersLiens = documents['hydra:member'];
        if (this.fichiersLiens.length > 0) {
          this.fichiersLiens.forEach(doc => {
            if (doc.nom == this.fichierTelecharge['nom']) {
              if (doc.type == 'liens') {
                this.dialogueShareService.addDialogue('Le nom du fichier existe déjà');
                existe = true;
              }
            }
          });
          // Vérifier la longueur du nom du fichier
          if (this.fichierTelecharge['nom'].length > 70) {
            this.dialogueShareService.addDialogue('Le nom du fichier est trop long');
            existe = true;
          }
          // Ajouter le fichier seulement si le nom n'existe pas et que la longueur est respecté
          if (existe === false) {
            this.fichiersCreateurLienService.ajouterFichier(this.fichierTelecharge)
              .then(() => {
                that.ngOnInit();
                this.dialogueShareService.addDialogue('Le fichier est bien enregistré');
              });
          }
        } else {
          if (this.fichierTelecharge['nom'].length > 70) {
            this.dialogueShareService.addDialogue('Le nom du fichier est trop long');
          } else {
            this.fichiersCreateurLienService.ajouterFichier(this.fichierTelecharge)
              .then(() => {
                that.ngOnInit();
                this.dialogueShareService.addDialogue('Le fichier est bien enregistré');
              });
          }
        }
      });
    }
  }

  deleteFichier(fichierId) {
    const that = this;
    this.dialogueShareService.addDialogue('êtes-vous sûr de vouloir supprimer ce fichier', function () {
      that.fichiersCreateurLienService.supprimerFichier(fichierId)
        .then(() => {
          that.dialogueShareService.addDialogue('Le fichier est bien supprimé');
          that.ngOnInit();
        })
        .catch(() => {
          that.dialogueShareService.addDialogue('Erreur de supression');
        });
    });
  }

}
