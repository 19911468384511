import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Parution} from '../../../../../model/parution/Parution';
import {ParutionService} from '../../../../../service/interne/parution/parutionService';
import {DiversParution} from '../../../../../model/parution/DiversParution';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-parution-fiche-details',
  templateUrl: './escal-parution-fiche-details.component.html',
  styleUrls: ['./escal-parution-fiche-details.component.scss']
})
export class EscalParutionFicheDetailsComponent implements OnInit {

  divers: DiversParution;
  @Input()
  parution: Parution;
  @Output()
  refresh = new EventEmitter();
  constructor(private parutionService: ParutionService, private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.parutionService.getDiversParution(this.parution.id).then( divers => {
      const div = divers['hydra:member'] as Array<DiversParution>;
      this.divers = div[0];
    });
  }

  changeInclurePressroom() {
    // modifier base escalnet
    this.parutionService.inclurePressroom(this.divers).then((divers) => {
      // modifier base intranet
      this.parutionService.inclurePressroomIntranet(this.parution.ancienId, this.parution.id).then((fin) => {
        this.ngOnInit();
        this.dialogueShareService.addDialogue('Modification effectuée');
      });
    });
  }

}
