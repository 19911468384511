import { Component, OnInit } from '@angular/core';
import {ParutionService} from '../../../../../service/interne/parution/parutionService';
import {ActivatedRoute} from '@angular/router';
import {OpenWithPostShareService} from '../../../../../service/partage/utilitaire/OpenWithPostShareService';
import {Parution} from '../../../../../model/parution/Parution';
import {Environement} from '../../../../../environement/environement';

@Component({
  selector: 'app-escal-parution-fiche-export',
  templateUrl: './escal-parution-fiche-export.component.html',
  styleUrls: ['./escal-parution-fiche-export.component.scss'],
  providers: [ ParutionService ]
})
export class EscalParutionFicheExportComponent implements OnInit {

  private sub: any;
  parution: Parution;
  toggleActive = false;
  id: boolean;
  titre: boolean;
  date: boolean;
  doc: boolean;
  resume: boolean;
  theme: boolean;
  qualification: boolean;
  format: boolean;
  typeRetombee: boolean;
  suitecp: boolean;
  campagne: boolean;
  typeAnnonce: boolean;
  pressroom: boolean;
  photo: boolean;
  illustration: boolean;
  logo: boolean;
  urlClient: boolean;
  agenda: boolean;
  graphique: boolean;
  nomination: boolean;
  tableau: boolean;
  courrier: boolean;
  commentaire: boolean;
  auteur: boolean;
  support: boolean;
  supportid: boolean;
  client: boolean;
  clientid: boolean;
  auteurs: boolean;
  auteursid: boolean;
  suiteSuivi: boolean;
  suiteSuiviid: boolean;
  constructor(private parutionService: ParutionService, private route: ActivatedRoute,
              private openWithPostService: OpenWithPostShareService) { }

  ngOnInit() {
    this.id = true;
    this.titre = true;
    this.date = true;
    this.doc = false;
    this.resume = true;
    this.theme = false;
    this.qualification = false;
    this.format = false;
    this.typeRetombee = false;
    this.suitecp = false;
    this.campagne = false;
    this.typeAnnonce = false;
    this.pressroom = true;
    this.photo = false;
    this.illustration = false;
    this.logo = false;
    this.urlClient = false;
    this.agenda = false;
    this.graphique = false;
    this.nomination = false;
    this.tableau = false;
    this.courrier = false;
    this.commentaire = false;
    this.auteur = false;
    this.support = true;
    this.supportid = false;
    this.client = true;
    this.clientid = false;
    this.auteurs = true;
    this.auteursid = false;
    this.suiteSuivi = false;
    this.suiteSuiviid = false;
    this.sub = this.route.params.subscribe(params => {
      this.parutionService.getParutionDetails(params.id)
        .then((parution) => {
          this.parution = parution as Parution;
        });
    });
  }

  exporterParution() {
    let url: string;
    this.parutionService.exporterParution(this.parution.id, this.id, this.titre, this.date, this.doc, this.resume,
      this.theme, this.qualification, this.format, this.typeRetombee, this.suitecp, this.campagne, this.typeAnnonce, this.pressroom,
      this.photo, this.illustration, this.logo, this.urlClient, this.agenda, this.graphique, this.nomination,
      this.tableau, this.courrier, this.commentaire, this.auteur, this.support, this.supportid, this.client, this.clientid,
      this.auteurs, this.auteursid, this.suiteSuivi, this.suiteSuiviid).then(() => {
      url = Environement.BASE_API_URL + '/excelStat/' + this.parution.id + 'export.xlsx';
      this.openWithPostService.openWindows(url);
    });
  }

  modifierActive() {
    if (this.toggleActive === false) {
      this.id = false;
      this.titre = false;
      this.date = false;
      this.doc = false;
      this.resume = false;
      this.theme = false;
      this.qualification = false;
      this.format = false;
      this.typeRetombee = false;
      this.suitecp = false;
      this.campagne = false;
      this.typeAnnonce = false;
      this.pressroom = false;
      this.photo = false;
      this.illustration = false;
      this.logo = false;
      this.urlClient = false;
      this.agenda = false;
      this.graphique = false;
      this.nomination = false;
      this.tableau = false;
      this.courrier = false;
      this.commentaire = false;
      this.auteur = false;
      this.support = false;
      this.supportid = false;
      this.client = false;
      this.clientid = false;
      this.auteurs = false;
      this.auteursid = false;
      this.suiteSuivi = false;
      this.suiteSuiviid = false;
    } else {
      this.id = true;
      this.titre = true;
      this.date = true;
      this.doc = true;
      this.resume = true;
      this.theme = true;
      this.qualification = true;
      this.format = true;
      this.typeRetombee = true;
      this.suitecp = true;
      this.campagne = true;
      this.typeAnnonce = true;
      this.pressroom = true;
      this.photo = true;
      this.illustration = true;
      this.logo = true;
      this.urlClient = true;
      this.agenda = true;
      this.graphique = true;
      this.nomination = true;
      this.tableau = true;
      this.courrier = true;
      this.commentaire = true;
      this.auteur = true;
      this.support = true;
      this.supportid = true;
      this.client = true;
      this.clientid = true;
      this.auteurs = true;
      this.auteursid = true;
      this.suiteSuivi = true;
      this.suiteSuiviid = true;
    }
  }

}
