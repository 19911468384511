import {Client} from './Client';
import {User} from '../user/User';


export class Membre {
  id: number;
  client: Client;
  premierMembre: User;
  deuxiemeMembre: User;
  troisiemeMembre: User;

  constructor(id: number, client: Client, premierMembre: User, deuxiemeMembre: User, troisiemeMembre: User) {
    this.id = id;
    this.client = client;
    this.premierMembre = premierMembre;
    this.deuxiemeMembre = deuxiemeMembre;
    this.troisiemeMembre = troisiemeMembre;
  }
}
