import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';
import {Parution} from '../../../model/parution/Parution';
import {ParutionService} from './parutionService';



@Injectable()
export class FichiersParutionService {
  private fichiersParutionURL = Environement.BASE_API_URL + '/api/fichiers_parutions';
  public parution: Parution;
  public idParution: string;
  parutionService: ParutionService;
  constructor(private http: HttpClient) { }

  ajouterDocument(idParution, documentInfo, typeDoc, isMultimedia) {
    const headers = this.initOptionHeader();
    return this.http.post(this.fichiersParutionURL,
      {nom: documentInfo.nom, type: typeDoc, multimedia: isMultimedia, url: documentInfo.url,
        value: documentInfo.value, parution: 'api/parutions/' + idParution},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  supprimerFichier(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.fichiersParutionURL + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getAllFichiersParution() {
    const headers = this.initOptionHeader();
    return this.http.get(this.fichiersParutionURL, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
