import { Component, OnInit } from '@angular/core';
import {Parution} from '../../../../../model/parution/Parution';
import {ParutionService} from '../../../../../service/interne/parution/parutionService';
import {ActivatedRoute, Router} from '@angular/router';
import {DiversParution} from '../../../../../model/parution/DiversParution';
import {ParutionContact} from '../../../../../model/parution/ParutionContact';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';

@Component({
  selector: 'app-escal-parution-fiche-copier',
  templateUrl: './escal-parution-fiche-copier.component.html',
  styleUrls: ['./escal-parution-fiche-copier.component.scss'],
  providers: [ ParutionService ]
})
export class EscalParutionFicheCopierComponent implements OnInit {

  private sub: any;
  parution: Parution;
  divers: DiversParution;
  copier: boolean;
  parutionCopie: Parution;
  diversCopie: DiversParution;
  parutionContacts: Array<ParutionContact>;
  parutionCopieId: number;
  constructor(private parutionService: ParutionService, private route: ActivatedRoute, private router: Router,
              private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.parutionCopie = new Parution(0, '', null, '', '', '', 0, new Date() , null, '',
      null, null, null, null, null, null, null, null, null, null,
      null, [], [], null, '', '',  null);
    this.diversCopie = new DiversParution(0, null, false, false, false, false, false, false, false,
      false, false, false, false, false, '', false, false, '', false,
      false, false, false, false);
    this.copier = false;
    this.parutionCopieId = 0;
    this.sub = this.route.params.subscribe(params => {
      this.parutionService.getParutionDetails(params.id)
        .then((parution) => {
          this.parution = parution as Parution;
        });
    });
  }

  copierParution() {
    // créer parution
    let client;
    let format;
    let typeRetombee;
    let typeAnnonce;
    if (this.parution.client != null) {
      client = this.parution.client.id;
    } else {
      client = null;
    }
    if (this.parution.format != null) {
      format = this.parution.format.id;
    } else {
      format = null;
    }
    if (this.parution.typeRetombee != null) {
      typeRetombee = this.parution.typeRetombee.id;
    } else {
      typeRetombee = null;
    }
    if (this.parution.typeAnnonce != null) {
      typeAnnonce = this.parution.typeAnnonce.id;
    } else {
      typeAnnonce = null;
    }
    this.parutionService.addParutionCopie(this.parution, client, format, typeRetombee, typeAnnonce)
      .then(parution => {
        this.parutionCopieId = parution.id;
        // si campagne renseignée
        if (this.parution.campagne != null) {
          this.parutionService.addCampagne(this.parution.campagne.id, parution.id).then();
        }
        // si thème renseigné
        if (this.parution.theme != null) {
          this.parutionService.addThemeParution(this.parution.theme.id, parution.id).then();
        }
        // si communiqué renseigné
        if (this.parution.suiteCommunique != null) {
          this.parutionService.addCommunique(this.parution.suiteCommunique.id, parution.id).then();
        }
        // si support renseigné
        if (this.parution.support != null) {
          this.parutionService.addSupportParution(this.parution.support.id, parution.id).then();
        }
        // si auteur(s) renseigné(s)
        this.parutionService.getParutionContacts(this.parution.id).then((contacts) => {
          this.parutionContacts = contacts['hydra:member'] as Array<ParutionContact>;
          // pour chaque contact, on le récupère et on l'associe à la parution
          this.parutionContacts.forEach( cont => {
            this.parutionService.addContactParution(cont.contact.id, parution.id).then();
          });
        });
        // si suite itw renseignée
        if (this.parution.suiteItw != null) {
          this.parutionService.addInterviewParution(this.parution.suiteItw.id, parution.id).then();
        }
        // si suite suivi renseigné
        if (this.parution.suiteSuivi != null) {
          this.parutionService.addSuiviParution(this.parution.suiteSuivi.id, parution.id).then();
        }
        this.parutionService.getDiversParution(this.parution.id).then((divers) => {
          const div = divers['hydra:member'] as Array<DiversParution>;
          this.divers = div[0];
          // importer checkbox et details de la parution dans une table à part
          this.parutionService.addDiversParution(this.divers, parution.id)
            .then(div => {
              // enregistrer parution dans la base d'intranet
              this.parutionService.importerParutionIntranet(parution.id, 0).then((ancienIdSoc) => {
                // insérer l'id d'intranet dans ancienId d'escalnet
                this.parutionService.addAncienIdParution(Number(ancienIdSoc), parution.id).then((retour) => {
                  // rappeler la fonction, cette fois pour s'assurer que les promises soient terminés
                  this.parutionService.importerParutionIntranet(parution.id, Number(ancienIdSoc)).then((fin) => {
                    this.dialogueShareService.addDialogue('La parution a bien été copiée');
                    this.copier = true;
                  });
                });
              });
            });
        });
      });
  }

}
