import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Parution} from '../../../../../model/parution/Parution';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {ParutionService} from '../../../../../service/interne/parution/parutionService';
import {ParutionContact} from '../../../../../model/parution/ParutionContact';
import {Environement} from '../../../../../environement/environement';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {FichiersParution} from '../../../../../model/parution/FichiersParution';
import {DiversParution} from '../../../../../model/parution/DiversParution';
import {Router} from '@angular/router';

@Component({
  selector: 'app-escal-parution-fiche-administratif',
  templateUrl: './escal-parution-fiche-administratif.component.html',
  styleUrls: ['./escal-parution-fiche-administratif.component.scss']
})
export class EscalParutionFicheAdministratifComponent implements OnInit {

  parutionContacts: Array<ParutionContact>;
  auteurAncienId: number;
  afficher: string;
  afficherContenu: string;
  commentaires: string;
  contenu: string;
  nbCaracteres: number;
  nbCaracteresContenu: number;
  urlTemp: string;
  nbCaracteresURL: number;
  fichiersAttaches: Array<FichiersParution>;
  contactsParution: Array<ParutionContact>;
  diversParution: Array<DiversParution>;
  baseServeur =  Environement.BASE_API_URL;
  @Input()
  parution: Parution;
  @Output()
  refresh = new EventEmitter();
  constructor(public formatIntranetShareService: FormatIntranetShareService, private parutionService: ParutionService,
              private dialogueShareService: DialogueShareService, private router: Router) { }

  ngOnInit() {
    this.auteurAncienId = 0;
    this.afficher = 'moins';
    this.afficherContenu = 'moins';
    this.nbCaracteres = 0;
    this.nbCaracteresContenu = 0;
    this.nbCaracteresURL = 0;
    this.parutionService.getParutionContacts(this.parution.id).then( parutionContacts => {
      this.parutionContacts = parutionContacts['hydra:member'] as Array<ParutionContact>;
    });
    // récupérer l'ancien id de l'auteur pour le lien vers sa fiche intranet
    if (this.parution.auteur != null) {
      this.parutionService.getAncienIdAuteur(this.parution.id).then((retour) => {
        if (retour !== 0) {
          this.auteurAncienId = retour;
        }
      });
    }
    // adapter l'affichage du commentaire si trop long
    if (this.parution.commentaire != null) {
      this.nbCaracteres = this.parution.commentaire.length;
      this.commentaires = this.parution.commentaire.substr(0, 150);
    }
    // adapter l'affichage du contenu si trop long
    if (this.parution.contenu != null) {
      this.nbCaracteresContenu = this.parution.contenu.length;
      this.contenu = this.parution.contenu.substr(0, 130);
    }
    // adapter l'affichage du lien si trop long
    if (this.parution.lien != null) {
      this.nbCaracteresURL = this.parution.lien.length;
      let i = 0;
      this.urlTemp = '';
      while (i < this.nbCaracteresURL) {
        this.urlTemp = this.urlTemp + this.parution.lien.substr(i, 45) + ' ';
        i = i + 45;
      }
    }
  }

  changerAffichage() {
    if (this.afficher === 'plus') {
      this.commentaires = this.parution.commentaire.substr(0, 150);
      this.afficher = 'moins';
    } else {
      this.commentaires = this.parution.commentaire;
      this.afficher = 'plus';
    }
  }

  changerAffichageContenu() {
    if (this.afficherContenu === 'plus') {
      this.contenu = this.parution.contenu.substr(0, 130);
      this.afficherContenu = 'moins';
    } else {
      this.contenu = this.parution.contenu;
      this.afficherContenu = 'plus';
    }
  }

  supprimerParution() {
    this.dialogueShareService.addDialogue('Supprimer la parution ?', () => {
      // supprimer les associations avant de supprimer la parution
      // pour chaque fichier attaché on supprime l'asso
      this.fichiersAttaches = [];
      this.parutionService.getAllFichiersParution(this.parution.id).then((fichiers) => {
        this.fichiersAttaches = fichiers['hydra:member'] as Array<FichiersParution>;
        if (this.fichiersAttaches != null) {
          this.fichiersAttaches.forEach((fichier: FichiersParution) => {
            this.parutionService.supprimerAssoFichiersParution(fichier.id).then(() => {
            });
          });
        }
      });
      // supprimer divers parution
      this.diversParution = [];
      this.parutionService.getDiversParution(this.parution.id).then( divers => {
        this.diversParution = divers['hydra:member'] as Array<DiversParution>;
        if (this.diversParution != null) {
          this.diversParution.forEach((diver: DiversParution) => {
            this.parutionService.supprimerDiversParution(diver.id).then(() => {
            });
          });
        }
      });
      // pour chaque contact auteur, on supprime l'asso
      this.contactsParution = [];
      this.parutionService.getParutionContacts(this.parution.id).then((conts) => {
        this.contactsParution = conts['hydra:member'] as Array<ParutionContact>;
        if (this.contactsParution != null) {
          this.contactsParution.forEach((assocont: ParutionContact) => {
            this.parutionService.supprimerAssoContactsParution(assocont.id).then(() => {
            });
          });
        }
      });
      // supprimer parution dans la base intranet
      this.parutionService.supprimerImportParutionIntranet(this.parution.ancienId)
        .then(() => {
            // supprimer parution
            this.parutionService.supprimerParution(this.parution.id).then((parution) => {
              this.router.navigate(['/escal/parutions']);
              this.dialogueShareService.addDialogue('La parution a bien été supprimé');
            });
        });
    });
  }

  envoyerParution() {
    this.dialogueShareService.addDialogue('Envoyer la parution ?', () => {
      this.parutionService.envoyerParutionReseauxSociaux(this.parution.ancienId).then();
    });
  }

}
