import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';


@Injectable()
export class PhotoParutionService {
  private photoParutionUrl = Environement.BASE_API_URL + '/api/photo_parutions';
  constructor(private http: HttpClient) { };

  addPhoto(idParution, photoUpload): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.photoParutionUrl , {'idParution': idParution, 'url' : idParution.toString() + '.' + photoUpload['extension'],
        'value': photoUpload['value'], 'filetype': photoUpload['extension']},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deletePhoto(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.photoParutionUrl + '/' + id , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;}
}
