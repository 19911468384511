import { Component, OnInit } from '@angular/core';
import {Client} from '../../../../model/client/Client';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {ActivatedRoute} from '@angular/router';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {NgxSpinnerService} from 'ngx-spinner';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {Environement} from '../../../../environement/environement';
import {OpenWithPostShareService} from '../../../../service/partage/utilitaire/OpenWithPostShareService';
import { Router } from '@angular/router';
import {ParutionService} from '../../../../service/interne/parution/parutionService';

@Component({
  selector: 'app-escal-client-rapport-activite',
  templateUrl: './escal-client-rapport-activite.component.html',
  styleUrls: ['./escal-client-rapport-activite.component.scss'],
  providers: [ ClientService, ParutionService ]
})
export class EscalClientRapportActiviteComponent implements OnInit {

  private sub: any;
  client: Client;
  datesChoisies: boolean;
  startMoment: Date;
  dateDebut: Date;
  dateFin: Date;
  cocherTout: boolean;
  format: boolean;
  suivis: boolean;
  citation: boolean;
  date: boolean;
  photo: boolean;
  commentaires: boolean;
  type: boolean;
  nature: boolean;
  audience: boolean;
  details: boolean;
  suiteItw: boolean;
  suite: boolean;
  suiteCP: boolean;
  journaliste: boolean;
  top: boolean;
  themes: boolean;
  articlesParus: Array<any> = [];
  topSupports: Array<any> = [];
  articlesThemes: Array<any> = [];
  aParaitre: Array<any> = [];
  itwRealises: Array<any> = [];
  itwFixes: Array<any> = [];
  itwNonAbouties: Array<any> = [];
  portages: Array<any> = [];
  decouverte: Array<any> = [];
  mails: Array<any> = [];
  calendriers: Array<any> = [];
  actionsRP: Array<any> = [];
  rappels: Array<any> = [];
  actionsRPu: Array<any> = [];
  rdvRealises: Array<any> = [];
  rdvFixes: Array<any> = [];
  veilles: Array<any> = [];
  totalArticlesParus: number;
  totalAParaitre: number;
  totalItwRealises: number;
  totalItwFixes: number;
  totalItwNonAbouties: number;
  totalPortages: number;
  totalDecouverte: number;
  totalMails: number;
  totalCalendriers: number;
  totalActionsRP: number;
  totalActionsRPu: number;
  totalRappels: number;
  totalRdvRealises: number;
  totalRdvFixes: number;
  totalVeilles: number;
  articlesParusFakeArray: Array<any>;
  topSupportsFakeArray: Array<any>;
  articlesThemesFakeArray: Array<any>;
  aParaitreFakeArray: Array<any>;
  itwRealisesFakeArray: Array<any>;
  itwFixesFakeArray: Array<any>;
  itwNonAboutiesFakeArray: Array<any>;
  portagesFakeArray: Array<any>;
  decouverteFakeArray: Array<any>;
  mailsFakeArray: Array<any>;
  calendriersFakeArray: Array<any>;
  actionsRPFakeArray: Array<any>;
  actionsRPuFakeArray: Array<any>;
  rappelsFakeArray: Array<any>;
  rdvRealisesFakeArray: Array<any>;
  rdvFixesFakeArray: Array<any>;
  veillesFakeArray: Array<any>;
  constructor(private dialogueShareService: DialogueShareService, private route: ActivatedRoute, private clientService: ClientService,
              private spinner: NgxSpinnerService, public formatIntranetShareService: FormatIntranetShareService,
              private openWithPostService: OpenWithPostShareService, private router: Router, private parutionService: ParutionService) { }

  ngOnInit() {
    const that = this;
    this.datesChoisies = false;
    this.cocherTout = false;
    this.format = false;
    this.suivis = false;
    this.citation = false;
    this.date = false;
    this.photo = false;
    this.commentaires = false;
    this.type = false;
    this.nature = false;
    this.audience = false;
    this.details = false;
    this.suiteItw = false;
    this.suite = false;
    this.suiteCP = false;
    this.journaliste = false;
    this.top = false;
    this.themes = false;
    this.totalArticlesParus = 0;
    this.totalAParaitre = 0;
    this.sub = this.route.params.subscribe(params => {
      this.clientService.getClientDetail(params.id)
        .then((retour) => {
          that.client = retour as Client;
        });
    });
  }

  toutCocher() {
    if (this.cocherTout == false) {
      this.format = true;
      this.suivis = true;
      this.citation = true;
      this.date = true;
      this.photo = true;
      this.commentaires = true;
      this.type = true;
      this.nature = true;
      this.audience = true;
      this.details = true;
      this.suiteItw = true;
      this.suite = true;
      this.suiteCP = true;
      this.journaliste = true;
      this.top = true;
      this.themes = true;
    } else {
      this.format = false;
      this.suivis = false;
      this.citation = false;
      this.date = false;
      this.photo = false;
      this.commentaires = false;
      this.type = false;
      this.nature = false;
      this.audience = false;
      this.details = false;
      this.suiteItw = false;
      this.suite = false;
      this.suiteCP = false;
      this.journaliste = false;
      this.top = false;
      this.themes = false;
    }
  }

  getInterractif() {
    let dateDebut = '';
    let dateFin = '';
    if (this.dateDebut != undefined) {
      dateDebut = this.dateDebut.getFullYear() + '-' + (this.dateDebut.getMonth() + 1).toString() + '-' + this.dateDebut.getDate();
    } else {
      dateDebut = '~';
    }
    if (this.dateFin != undefined) {
      dateFin = this.dateFin.getFullYear() + '-' + (this.dateFin.getMonth() + 1).toString() + '-' + this.dateFin.getDate();
    } else {
      dateFin = '~';
    }
    this.spinner.show();
    this.clientService.getClientRA(this.client.ancienId, dateDebut, dateFin)
      .then((datas) => {
        this.articlesParus = datas.articlesParus;
        this.topSupports = datas.topSupports;
        this.articlesThemes = datas.articlesThemes;
        this.aParaitre = datas.aParaitre;
        this.itwRealises = datas.itwRealises;
        this.itwFixes = datas.itwFixes;
        this.itwNonAbouties = datas.itwNonAbouties;
        this.portages = datas.portages;
        this.decouverte = datas.decouverte;
        this.mails = datas.mails;
        this.calendriers = datas.calendriers;
        this.actionsRP = datas.actionsRP;
        this.rappels = datas.rappels;
        this.actionsRPu = datas.actionsRPu;
        this.rdvRealises = datas.rdvRealises;
        this.rdvFixes = datas.rdvFixes;
        this.veilles = datas.veilles;
        // total infos donnees
        this.totalArticlesParus = Object.keys(this.articlesParus).length;
        this.totalAParaitre = Object.keys(this.aParaitre).length;
        this.totalItwRealises = Object.keys(this.itwRealises).length;
        this.totalItwFixes = Object.keys(this.itwFixes).length;
        this.totalItwNonAbouties = Object.keys(this.itwNonAbouties).length;
        this.totalPortages = Object.keys(this.portages).length;
        this.totalDecouverte = Object.keys(this.decouverte).length;
        this.totalMails = Object.keys(this.mails).length;
        this.totalCalendriers = Object.keys(this.calendriers).length;
        this.totalActionsRP = Object.keys(this.actionsRP).length;
        this.totalActionsRPu = Object.keys(this.actionsRPu).length;
        this.totalRappels = Object.keys(this.rappels).length;
        this.totalRdvRealises = Object.keys(this.rdvRealises).length;
        this.totalRdvFixes = Object.keys(this.rdvFixes).length;
        this.totalVeilles = Object.keys(this.veilles).length;
        // fake array permettant de faire une boucle for sur la vue
        this.articlesParusFakeArray = new Array(Object.keys(this.articlesParus).length);
        this.topSupportsFakeArray = new Array(Object.keys(this.topSupports).length);
        this.articlesThemesFakeArray = new Array(Object.keys(this.articlesThemes).length);
        this.aParaitreFakeArray = new Array(Object.keys(this.aParaitre).length);
        this.itwRealisesFakeArray = new Array(Object.keys(this.itwRealises).length);
        this.itwFixesFakeArray = new Array(Object.keys(this.itwFixes).length);
        this.itwNonAboutiesFakeArray = new Array(Object.keys(this.itwNonAbouties).length);
        this.portagesFakeArray = new Array(Object.keys(this.portages).length);
        this.decouverteFakeArray = new Array(Object.keys(this.decouverte).length);
        this.mailsFakeArray = new Array(Object.keys(this.mails).length);
        this.calendriersFakeArray = new Array(Object.keys(this.calendriers).length);
        this.actionsRPFakeArray = new Array(Object.keys(this.actionsRP).length);
        this.actionsRPuFakeArray = new Array(Object.keys(this.actionsRPu).length);
        this.rappelsFakeArray = new Array(Object.keys(this.rappels).length);
        this.rdvRealisesFakeArray = new Array(Object.keys(this.rdvRealises).length);
        this.rdvFixesFakeArray = new Array(Object.keys(this.rdvFixes).length);
        this.veillesFakeArray = new Array(Object.keys(this.veilles).length);
        // remplacer caractère speciaux
        for (let i = 0; i < Object.keys(this.articlesParus).length; i++) {
          this.articlesParus[i].nom = this.articlesParus[i].nom.replace('&#039;', '’');
          this.articlesParus[i].nom = this.articlesParus[i].nom.replace('l&#039;', 'l’');
          this.articlesParus[i].nom = this.articlesParus[i].nom.replace('s&#039;', 's’');
        }
        for (let i = 0; i < Object.keys(this.articlesParus).length; i++) {
          this.articlesParus[i].citation = this.articlesParus[i].citation.replace('&#039;', '’');
          this.articlesParus[i].citation = this.articlesParus[i].citation.replace('l&#039;', 'l’');
          this.articlesParus[i].citation = this.articlesParus[i].citation.replace('s&#039;', 's’');
        }
        for (let i = 0; i < Object.keys(this.itwRealises).length; i++) {
          this.itwRealises[i].theme = this.itwRealises[i].theme.replace('&#039;', '’');
          this.itwRealises[i].theme = this.itwRealises[i].theme.replace('l&#039;', 'l’');
          this.itwRealises[i].theme = this.itwRealises[i].theme.replace('s&#039;', 's’');
        }
        for (let i = 0; i < Object.keys(this.itwFixes).length; i++) {
          this.itwFixes[i].theme = this.itwFixes[i].theme.replace('&#039;', '’');
          this.itwFixes[i].theme = this.itwFixes[i].theme.replace('l&#039;', 'l’');
          this.itwFixes[i].theme = this.itwFixes[i].theme.replace('s&#039;', 's’');
        }
        for (let i = 0; i < Object.keys(this.itwNonAbouties).length; i++) {
          this.itwNonAbouties[i].theme = this.itwNonAbouties[i].theme.replace('&#039;', '’');
          this.itwNonAbouties[i].theme = this.itwNonAbouties[i].theme.replace('l&#039;', 'l’');
          this.itwNonAbouties[i].theme = this.itwNonAbouties[i].theme.replace('s&#039;', 's’');
        }
        for (let i = 0; i < Object.keys(this.mails).length; i++) {
          this.mails[i].nom = this.mails[i].nom.replace('&#039;', '’');
          this.mails[i].nom = this.mails[i].nom.replace('l&#039;', 'l’');
          this.mails[i].nom = this.mails[i].nom.replace('s&#039;', 's’');
          this.mails[i].nom = this.mails[i].nom.replace('&amp;', '&');
        }
        for (let i = 0; i < Object.keys(this.actionsRP).length; i++) {
          this.actionsRP[i].support = this.actionsRP[i].support.replace('&#039;', '’');
          this.actionsRP[i].support = this.actionsRP[i].support.replace('l&#039;', 'l’');
          this.actionsRP[i].support = this.actionsRP[i].support.replace('s&#039;', 's’');
          this.actionsRP[i].support = this.actionsRP[i].support.replace('&amp;', '&');
          this.actionsRP[i].journaliste = this.actionsRP[i].journaliste.replace('&#039;', '’');
          this.actionsRP[i].journaliste = this.actionsRP[i].journaliste.replace('l&#039;', 'l’');
          this.actionsRP[i].journaliste = this.actionsRP[i].journaliste.replace('s&#039;', 's’');
          this.actionsRP[i].journaliste = this.actionsRP[i].journaliste.replace('&amp;', '&');
        }
        for (let i = 0; i < Object.keys(this.actionsRPu).length; i++) {
          this.actionsRPu[i].journaliste = this.actionsRPu[i].journaliste.replace('&#039;', '’');
          this.actionsRPu[i].journaliste = this.actionsRPu[i].journaliste.replace('l&#039;', 'l’');
          this.actionsRPu[i].journaliste = this.actionsRPu[i].journaliste.replace('s&#039;', 's’');
          this.actionsRPu[i].journaliste = this.actionsRPu[i].journaliste.replace('&amp;', '&');
        }
        for (let i = 0; i < Object.keys(this.rappels).length; i++) {
          this.rappels[i].titre = this.rappels[i].titre.replace('&#039;', '’');
          this.rappels[i].titre = this.rappels[i].titre.replace('l&#039;', 'l’');
          this.rappels[i].titre = this.rappels[i].titre.replace('s&#039;', 's’');
          for (let j = 0; j < this.rappels[i].contacts.length; j++) {
            this.rappels[i].contacts[j].journaliste = this.rappels[i].contacts[j].journaliste.replace('&#039;', '’');
            this.rappels[i].contacts[j].journaliste = this.rappels[i].contacts[j].journaliste.replace('l&#039;', 'l’');
            this.rappels[i].contacts[j].journaliste = this.rappels[i].contacts[j].journaliste.replace('s&#039;', 's’');
            for (let k = 0; k < this.rappels[i].contacts[j].supports.length; k++) {
              this.rappels[i].contacts[j].supports[k].support = this.rappels[i].contacts[j].supports[k].support.replace('&#039;', '’');
              this.rappels[i].contacts[j].supports[k].support = this.rappels[i].contacts[j].supports[k].support.replace('l&#039;', 'l’');
              this.rappels[i].contacts[j].supports[k].support = this.rappels[i].contacts[j].supports[k].support.replace('L&#039;', 'L’');
              this.rappels[i].contacts[j].supports[k].support = this.rappels[i].contacts[j].supports[k].support.replace('s&#039;', 's’');
              this.rappels[i].contacts[j].supports[k].support = this.rappels[i].contacts[j].supports[k].support.replace('&amp;', '&');
            }
          }
        }
        this.datesChoisies = true;
        this.spinner.hide();
      });
  }

  getWord() {
    let dateDebut = '';
    let dateFin = '';
    if (this.dateDebut != undefined) {
      dateDebut = this.dateDebut.getFullYear() + '-' + (this.dateDebut.getMonth() + 1).toString() + '-' + this.dateDebut.getDate();
    } else {
      dateDebut = '~';
    }
    if (this.dateFin != undefined) {
      dateFin = this.dateFin.getFullYear() + '-' + (this.dateFin.getMonth() + 1).toString() + '-' + this.dateFin.getDate();
    } else {
      dateFin = '~';
    }
    this.spinner.show();
    this.clientService.getClientRAWord(this.client.ancienId, dateDebut, dateFin, this.format, this.suivis, this.citation, this.date, this.photo, this.commentaires, this.type, this.nature, this.audience, this.details, this.suiteItw, this.suite, this.suiteCP, this.top, this.themes)
      .then(() => {
        let url: string;
        url = Environement.BASE_API_URL + '/RA/' + this.client.ancienId + 'RA.docx';
        this.openWithPostService.openWindows(url);
        this.spinner.hide();
      });
  }

  afficherSuivi(suiviAncienId) {
    // récupérer l'id escalnet du suivi
    this.clientService.getIdSuivi(suiviAncienId).then(id => {
      if (id != 0 || id != '0') {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([`/escal/suivi/${id}`])
        );
        window.open(url, '_blank');
      } else {
        const url = 'http://intranet.escalconsulting.com/contacts_suivi_cms.php?action=vuedetail&ID_suivi=' + suiviAncienId;
        window.open(url, '_blank');
      }
    });
  }

  afficherParution(parutionAncienId) {
    // récupérer l'id escalnet de la parution si existe
    this.parutionService.getParutionByAncienId(parutionAncienId).then(retour => {
      if (retour['hydra:member'][0] != null && retour['hydra:member'][0] != undefined) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([`/escal/parution/${retour['hydra:member'][0].id}`])
        );
        window.open(url, '_blank');
      } else {
        const url = 'http://intranet.escalconsulting.com/parution_cms.php?action=vuedetail&parution_ID=' + parutionAncienId;
        window.open(url, '_blank');
      }
    });
  }

}
