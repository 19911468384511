import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Client} from '../../../../../model/client/Client';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {PaginationPaginateurComponent} from '../../../../utilitaire/pagination/pagination-paginateur/pagination-paginateur.component';
import {Parution} from '../../../../../model/parution/Parution';
import {ParutionService} from '../../../../../service/interne/parution/parutionService';

@Component({
  selector: 'app-escal-client-fiche-parutions',
  templateUrl: './escal-client-fiche-parutions.component.html',
  styleUrls: ['./escal-client-fiche-parutions.component.scss'],
  providers: [ ParutionService ]
})
export class EscalClientFicheParutionsComponent implements OnInit {

  parutionsAssocies: Array<Parution>;
  curentPage = 1;
  maxPage: number;
  total: number;
  fakeArray: Array<any>;
  page: number;
  @Input()
  client: Client;
  @Output()
  refresh = new EventEmitter();
  @ViewChild(PaginationPaginateurComponent) paginationComponent: PaginationPaginateurComponent;
  constructor(public formatIntranetShareService: FormatIntranetShareService, private parutionService: ParutionService) { }

  ngOnInit() {
    this.page = 1;
    this.parutionService.getAllParutionsClient(this.client.id, this.curentPage).then((suivis) => {
      this.parutionsAssocies = suivis['hydra:member'] as Array<Parution>;
      this.total = suivis['hydra:totalItems'];
      this.getMaxPage(suivis['hydra:totalItems']);
      this.fakeArray = new Array(this.maxPage);
    });
  }
  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.ngOnInit();
  }

  accesPage() {
    this.curentPage = this.page;
    this.paginationComponent.page = this.page;
    this.ngOnInit();
    this.paginationComponent.initPage();
  }

}
