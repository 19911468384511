import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Parution} from '../../../../../model/parution/Parution';
import {FormatIntranetShareService} from '../../../../../service/partage/prototype/FormatIntranetShareService';
import {ParutionService} from '../../../../../service/interne/parution/parutionService';
import {DialogueShareService} from '../../../../../service/partage/utilitaire/DialogueShareService';
import {DiversParution} from '../../../../../model/parution/DiversParution';

@Component({
  selector: 'app-escal-parution-fiche-informations',
  templateUrl: './escal-parution-fiche-informations.component.html',
  styleUrls: ['./escal-parution-fiche-informations.component.scss']
})
export class EscalParutionFicheInformationsComponent implements OnInit {

  pdfURL: string;
  multimediaURL: string;
  pdfNom: string;
  multimediaNom: string;
  afficheAssoMailing: boolean;
  afficheAssoListe: boolean;
  listeId: number;
  champRechercheListe: string;
  listesId: Array<any> = [];
  listes: Array<any> = [];
  listesRecherche: Array<any> = [];
  rechercheEncoursListe: boolean;
  associer: boolean;
  compteurMultimedia: number;
  divers: DiversParution;
  @Input()
  parution: Parution;
  @Output()
  refresh = new EventEmitter();
  constructor(public formatIntranetShareService: FormatIntranetShareService, private parutionService: ParutionService,
              private dialogueShareService: DialogueShareService) { }

  ngOnInit() {
    this.compteurMultimedia = 0;
    this.pdfURL = '';
    this.multimediaURL = '';
    this.pdfNom = '';
    this.multimediaNom = '';
    this.afficheAssoMailing = false;
    this.afficheAssoListe = false;
    this.rechercheEncoursListe = false;
    this.listeId = 0;
    this.champRechercheListe = '';
    this.associer = false;
    this.parution.fichiersParutions.forEach( fichiers => {
      if (fichiers.multimedia == true) {
        this.multimediaNom = fichiers.nom;
        this.multimediaURL = fichiers.url;
        this.compteurMultimedia = this.compteurMultimedia + 1;
      }
      if (fichiers.multimedia == false) {
        this.pdfNom = fichiers.nom;
        this.pdfURL = fichiers.url;
      }
    });
    this.parutionService.getListeParutionRecherche('~', this.parution.client.ancienId).then((retour) => {
      this.listesRecherche = retour;
      this.listeId = retour[0].id;
      this.listesRecherche.forEach(liste => {
        if (liste.nom != '' && liste.nom != null) {
          liste.nom = liste.nom.replace('&amp;', '&');
          liste.nom = liste.nom.replace('&#039;', '’');
          liste.nom = liste.nom.replace('L&#039;', 'L’');
        }
      });
    });
    this.parutionService.getListesParution(this.parution.ancienId).then((retour) => {
      this.listes = retour;
      this.listes.forEach(liste => {
        if (liste.libelle != '' && liste.libelle != null) {
          liste.libelle = liste.libelle.replace('&amp;', '&');
          liste.libelle = liste.libelle.replace('&#039;', '’');
          liste.libelle = liste.libelle.replace('L&#039;', 'L’');
        }
      });
    });
    this.parutionService.getDiversParution(this.parution.id).then( divers => {
      const div = divers['hydra:member'] as Array<DiversParution>;
      this.divers = div[0];
      this.associer = this.divers.mailing;
    });
  }

  afficherAssoMailing() {
    if (this.afficheAssoMailing === false) {
      this.afficheAssoMailing = true;
      this.afficheAssoListe = false;
    } else {
      this.afficheAssoMailing = false;
    }
  }

  afficherAssoListe() {
    if (this.afficheAssoListe === false) {
      this.afficheAssoListe = true;
      this.afficheAssoMailing = false;
    } else {
      this.afficheAssoListe = false;
    }
  }

  rechercheListe() {
    this.listesRecherche = [];
    this.listeId = 0;
    if (this.champRechercheListe.length > 2 && !this.rechercheEncoursListe) {
      this.rechercheEncoursListe = true;
      this.champRechercheListe = this.champRechercheListe.replace(' ', '.');
      this.parutionService.getListeParutionRecherche('~', this.parution.client.ancienId).then((retour) => {
        this.listesRecherche = retour;
        this.listeId = retour[0].id;
        this.listesRecherche.forEach(liste => {
          if (liste.nom != '' && liste.nom != null) {
            liste.nom = liste.nom.replace('&amp;', '&');
            liste.nom = liste.nom.replace('&#039;', '’');
            liste.nom = liste.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheListe = this.champRechercheListe.replace('.', ' ');
      });
      this.rechercheEncoursListe = false;
    } else {
      this.parutionService.getListeParutionRecherche('~', this.parution.client.ancienId).then((retour) => {
        this.listesRecherche = retour;
        this.listeId = retour[0].id;
        this.listesRecherche.forEach(liste => {
          if (liste.nom != '' && liste.nom != null) {
            liste.nom = liste.nom.replace('&amp;', '&');
            liste.nom = liste.nom.replace('&#039;', '’');
            liste.nom = liste.nom.replace('L&#039;', 'L’');
          }
        });
      });
    }
  }

  associerListe() {
    if (this.listeId != 0) {
      this.parutionService.associerListeParution(this.listeId, this.parution.ancienId).then(() => {
        // recharger les listes associées à la parution
        this.listes = [];
        this.parutionService.getListesParution(this.parution.ancienId).then((retour) => {
          this.listes = retour;
          this.listes.forEach(liste => {
            if (liste.libelle != '' && liste.libelle != null) {
              liste.libelle = liste.libelle.replace('&amp;', '&');
              liste.libelle = liste.libelle.replace('&#039;', '’');
              liste.libelle = liste.libelle.replace('L&#039;', 'L’');
            }
          });
        });
        this.dialogueShareService.addDialogue('Liste associée');
      });
    }
  }

  desassocierListe() {
    if (this.listesId.length > 0) {
      let listesIdsSelect = '';
      // envoi les id à l'api sous forme de chaine de caractère
      this.listesId.forEach( listeId => {
        listesIdsSelect = listesIdsSelect + listeId + ',';
      });
      this.parutionService.desassocierListeParution(listesIdsSelect, this.parution.ancienId).then(() => {
        // recharger les listes associées à la parution
        this.listes = [];
        this.parutionService.getListesParution(this.parution.ancienId).then((retour) => {
          this.listes = retour;
          this.listes.forEach(liste => {
            if (liste.libelle != '' && liste.libelle != null) {
              liste.libelle = liste.libelle.replace('&amp;', '&');
              liste.libelle = liste.libelle.replace('&#039;', '’');
              liste.libelle = liste.libelle.replace('L&#039;', 'L’');
            }
          });
        });
        this.dialogueShareService.addDialogue('Liste(s) désassociée(s)');
      });
    }
  }

  associerParutionMailing() {
    if (this.pdfURL != '') {
      if (this.associer == false) {
        this.associer = true;
        // modifier sur escalnet
        this.parutionService.associerParutionMailing(this.divers.id, this.associer).then(() => {
          // modifier sur intranet
          this.parutionService.associerMailingIntranet(this.parution.ancienId, this.associer).then(() => {
            this.dialogueShareService.addDialogue('Parution associée');
          });
        });
      } else if (this.associer == true) {
        this.associer = false;
        // modifier sur escalnet
        this.parutionService.associerParutionMailing(this.divers.id, this.associer).then(() => {
          // modifier sur intranet
          this.parutionService.associerMailingIntranet(this.parution.ancienId, this.associer).then(() => {
            this.dialogueShareService.addDialogue('Parution désassociée');
          });
        });
      }
    } else {
      this.dialogueShareService.addDialogue('La parution n\'a pas de fichier attaché');
    }
  }

}
