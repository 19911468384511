import {Component, Input, OnInit} from '@angular/core';
import {Parution} from '../../../../../model/parution/Parution';

@Component({
  selector: 'app-pagination-content-parution',
  templateUrl: './pagination-content-parution.component.html',
  styleUrls: ['./pagination-content-parution.component.scss']
})
export class PaginationContentParutionComponent implements OnInit {

  @Input()
  parutions: Array<Parution>;
  constructor() { }

  ngOnInit() {
  }

}
