import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';


@Injectable()
export class FichiersCreateurLienService {
  private fichiersURL = Environement.BASE_API_URL + '/api/fichiers_createur_liens';
  constructor(private http: HttpClient) { }

  ajouterFichier(fichierInfo) {
    const headers = this.initOptionHeader();
    const now = new Date();
    const DateCreFormat = now.getFullYear() + '-' + (now.getMonth() + 1).toString() + '-' + now.getDate() + ' ' + now.getHours() + ':' + now.getMinutes() + ':00' ;
    return this.http.post(this.fichiersURL,
      {nom: fichierInfo.nom, type: 'liens', format: fichierInfo.format, creation: DateCreFormat, url: fichierInfo.url,
        value: fichierInfo.value},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getFichiersCreateurLien(): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.fichiersURL, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerFichier(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.fichiersURL + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }
  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
  }
}
