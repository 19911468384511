import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

import {ClientService} from '../../../../service/interne/client/ClientService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {LogoService} from '../../../../service/interne/utilitaire/LogoService';

import {Client} from '../../../../model/client/Client';
import {Organisation} from '../../../../model/organisation/Organisation';
import {Coordonnees} from '../../../../model/information/Coordonnees';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {CodeApe} from '../../../../model/organisation/CodeApe';
import {CodeApeService} from '../../../../service/interne/organisation/CodeApeService';
import {FormeJuridique} from '../../../../model/organisation/FormeJuridique';
import {Pays} from '../../../../model/information/Pays';
import {FormeJuridiqueService} from '../../../../service/interne/organisation/FormeJuridiqueService';
import {PaysService} from '../../../../service/interne/contact/PaysService';
import {CoordonneesService} from '../../../../service/interne/coordonnees/CoordonneesService';
import {Etat} from '../../../../model/information/Etat';
import {EtatService} from '../../../../service/interne/etat/EtatService';
import {Membre} from '../../../../model/client/Membre';


@Component({
  selector: 'app-escal-client-add',
  templateUrl: './escal-client-add.component.html',
  styleUrls: ['./escal-client-add.component.scss'],
  providers: [ClientService, LogoService, EtatService]
})
export class EscalClientAddComponent implements OnInit {


  client: Client;
  societe: Organisation;
  coordonnee: Coordonnees;
  membre: Membre;
  dirigeantId: number;
  societeId: number;
  codeId: number;
  formeId: number;
  etatId: number;
  genreId: number;
  categId: number;
  paysId: number;
  clientId: number;
  afficheDirigeant: boolean;
  afficheCode: boolean;
  affiche: boolean;
  afficheCateg: boolean;
  dirigeantsRecherche: Array<any> = [];
  societesRecherche: Array<any> = [];
  categoriesRecherche: Array<any> = [];
  codes: Array<CodeApe>;
  formes: Array<FormeJuridique>;
  pays: Array<Pays>;
  etats: Array<Etat>;
  champRechercheSoc: string;
  champRechercheCode: string;
  champRechercheCateg: string;
  champRechercheDirigeant: string;
  rechercheEncoursDirigeant = false;
  rechercheEncours = false;
  rechercheEncoursCateg = false;
  generique: boolean;
  coupure: boolean;
  logoUpload: Array<any> = [];

  constructor(private dialogueShareService: DialogueShareService, private clientService: ClientService, private logoService: LogoService,
              private router: Router, private authShareService: AuthShareService,
              public formatIntranetShareService: FormatIntranetShareService, private organisationService: OrganisationService,
              private codeApeService: CodeApeService, private formeJuridiqueService: FormeJuridiqueService,
              private paysService: PaysService, private coordonneesService: CoordonneesService, private etatService: EtatService) {
  }

  ngOnInit() {
    this.dirigeantId = 0;
    this.codeId = 0;
    this.societeId = 0;
    this.etatId = 1;
    this.formeId = 0;
    this.categId = 0;
    this.paysId = 0;
    this.champRechercheDirigeant = '';
    this.champRechercheCode = '';
    this.champRechercheCateg = '';
    this.champRechercheSoc = '';
    this.afficheDirigeant = false;
    this.afficheCode = false;
    this.afficheCateg = false;
    this.affiche = false;
    this.clientId = 0;
    this.client = new Client(0, '', true, '', null, [], '', '',
      null, [], [], null, null, null, '', [], null);
    this.societe = new Organisation(0, 0, '', null, null, '', '', '' , '', '',
      this.authShareService.authUser, new Date(), null, null, null, null, null, null, null, [], [], null, []);
    this.coordonnee = new Coordonnees(0, '', '', '', '', '', '', '', '', null,
      '', '', this.authShareService.authUser, new Date(), null, 0, null, null, null);
    this.membre = new Membre(0, null, null, null, null);
    this.formeJuridiqueService.getFormesJuridiques().then(formesJ => {
      this.formes = formesJ['hydra:member'] as Array<FormeJuridique>;
    });
    this.paysService.getAllPays().then(pays => {
      this.pays = pays['hydra:member'] as Array<Pays>;
    });
    this.etatService.getEtats().then(etats => {
      this.etats = etats['hydra:member'] as Array<Etat>;
    });
  }

  affecterNomClient() {
    this.client.nom = this.societe.nom;
  }

  adressGeneriqueChange() {
    if (this.generique === false) {
      this.client.adresseGenerique = '';
    } else {
      if (this.client.nom.length > 2) {
        this.client.adresseGenerique = this.client.nom.toLowerCase().replace(/\s/g, '') + '@escalconsulting.com';
      } else {
        this.generique = false;
        this.dialogueShareService.addDialogue('merci de d\'entrer le nom du client');
      }
    }
  }

  adressCoupureChange() {
    if (this.coupure === false) {
      this.client.adresseGenerique = '';
    } else {
      if (this.client.nom.length > 2) {
        this.client.adresseCoupure = 'coupures-' + this.client.nom.toLowerCase().replace(/\s/g, '') + '@escalconsulting.com';
      } else {
        this.coupure = false;
        this.dialogueShareService.addDialogue('Merci de d\'entrer le nom du client');
      }
    }
  }

  saveClient() {
    this.client.nom = this.societe.nom;
    const that = this;
    var nb = 0;
    var compteur = 0;
    this.clientService.getClientList(1).then((clients) => {
      clients['hydra:member'].forEach(client => {
        nb = nb + 1;
        if (client.nom == this.client.nom) {
          this.dialogueShareService.addDialogue('Le nom saisi existe déjà');
        }  else if (nb === clients['hydra:member'].length) {
          if (that.logoUpload['filename'] === undefined) {
            this.clientService.addClient(this.client, this.etatId)
              .then((retour) => {
                that.client = retour as Client;
                this.clientId = that.client.id;
                this.enregistrerSociete();
                this.router.navigate(['/escal/client/actuel']);
                this.dialogueShareService.addDialogue('Le client est bien créé');
              });
          } else {
            that.logoService.getLogo().then((logos) => {
              logos['hydra:member'].forEach(logo => {
                compteur = compteur + 1;
                if (logo.url == that.logoUpload['filename']) {
                  this.dialogueShareService.addDialogue('Un fichier porte déjà ce nom de logo');
                } else if (compteur === logos['hydra:member'].length) {
                  this.clientService.addClient(this.client, this.etatId)
                    .then((retour) => {
                      that.client = retour as Client;
                      this.clientId = that.client.id;
                      this.enregistrerSociete();
                      that.logoService.addLogo(that.client.id, that.logoUpload)
                        .then((retour1) => {
                          this.router.navigate(['/escal/client/actuel']);
                          this.dialogueShareService.addDialogue('Le client est bien créé');
                        });
                    });
                }
              });
            });
          }
        }
      });
    });
  }

  changeLogo(event) {
    const that = this;
    const reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        that.logoUpload['filename'] = file.name;
        that.logoUpload['filetype'] = file.type;
        if (typeof reader.result === 'string') {
          that.logoUpload['value'] = reader.result.split(',')[1];
        }
      };
    }
  }

  rechercheDirigeant() {
    this.afficheDirigeant = true;
    if (this.champRechercheDirigeant.length > 2 && !this.rechercheEncoursDirigeant) {
      this.rechercheEncoursDirigeant = true;
      this.champRechercheDirigeant = this.champRechercheDirigeant.replace(' ', '.');
      this.organisationService.getDirigeantRecherche(this.champRechercheDirigeant).then((retour) => {
        this.dirigeantsRecherche = retour;
        this.dirigeantsRecherche.forEach(dirigeant => {
          if (dirigeant.nomPrenom != '' && dirigeant.nomPrenom != null) {
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&amp;', '&');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&#039;', '’');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheDirigeant = this.champRechercheDirigeant.replace('.', ' ');
      });
      this.rechercheEncoursDirigeant = false;
    }
  }

  rechercheSociete() {
    this.affiche = true;
    if (this.champRechercheSoc.length > 2 && !this.rechercheEncours) {
      this.rechercheEncours = true;
      this.champRechercheSoc = this.champRechercheSoc.replace(' ', '.');
      this.organisationService.getSocieteRecherche(this.champRechercheSoc).then((retour) => {
        this.societesRecherche = retour;
        this.societesRecherche.forEach(societe => {
          if (societe.nom != '' && societe.nom != null) {
            societe.nom = societe.nom.replace('&amp;', '&');
            societe.nom = societe.nom.replace('&#039;', '’');
            societe.nom = societe.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSoc = this.champRechercheSoc.replace('.', ' ');
      });
      this.rechercheEncours = false;
    }
  }

  rechercheCode() {
    this.codeApeService.getCodesApeRecherche(this.champRechercheCode).then(codes => {
      this.codes = codes['hydra:member'] as Array<CodeApe>;
      this.afficheCode = true;
    });
  }

  rechercheCategories() {
    this.afficheCateg = true;
    if (this.champRechercheCateg.length > 2 && !this.rechercheEncoursCateg) {
      this.rechercheEncoursCateg = true;
      this.champRechercheCateg = this.champRechercheCateg.replace(' ', '.');
      this.organisationService.getCategoriesRecherche(this.champRechercheCateg).then((retour) => {
        this.categoriesRecherche = retour;
        this.categoriesRecherche.forEach(categorie => {
          if (categorie.nom != '' && categorie.nom != null) {
            categorie.nom = categorie.nom.replace('&amp;', '&');
            categorie.nom = categorie.nom.replace('&#039;', '’');
            categorie.nom = categorie.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheCateg = this.champRechercheCateg.replace('.', ' ');
      });
      this.rechercheEncoursCateg = false;
    }
  }

  enregistrerSociete() {
    // genre intranet "Actif" parce que Client
    this.genreId = 2;
    let dirigeantNomPrenom = '';
    // informations obligatoires : Nom et Genre de la société
    if (this.societe.nom != '') {
      if (this.genreId != 0) {
        this.organisationService.addOrganisation(this.societe, this.formeId, this.codeId, this.genreId).then(org => {
          const organisation = org as Organisation;
          // si société parente renseignée
          if (this.societeId != 0) {
            // on importe la societe à laquelle la nouvelle organisation appartient
            this.organisationService.importerOrganisation(this.societeId).then(() => {
              // on récupère la société importée
              this.organisationService.getDoublonEscalnetSiExiste(this.societeId).then(societe => {
                const socId = societe['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addSocieteParent(socId, organisation.id).then(() => {
                });
              });
            });
          }
          // si dirigeant renseigné
          if (this.dirigeantId != 0) {
            // on importe le dirigeant
            this.organisationService.importerDirigeant(this.dirigeantId).then(() => {
              // on récupère le dirigeant renseigné
              this.organisationService.getDirigeantByAncienId(this.dirigeantId).then((dirigeant) => {
                const dirId = dirigeant['hydra:member'][0].id;
                dirigeantNomPrenom = dirigeant['hydra:member'][0].prenom + ' ' + dirigeant['hydra:member'][0].nom;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addDirigeant(dirId, organisation.id).then(() => {
                });
              });
            });
          }
          // si catégorie générale renseignée
          if (this.categId != 0) {
            // on importe la catégorie
            this.organisationService.importerCategorie(this.categId).then(() => {
              // on récupère la catégorie renseignée
              this.organisationService.getCategorieByAncienId(this.categId).then((categorie) => {
                const catId = categorie['hydra:member'][0].id;
                // on l'assigne à la nouvelle organisation
                this.organisationService.addCategorie(catId, organisation.id).then(() => {
                });
              });
            });
          }
          // si au moins un champs de coordonnees renseigné
          if (this.coordonnee.premierTel != '' || this.coordonnee.deuxiemeTel != '' || this.coordonnee.email != '' || this.coordonnee.rue != '' || this.coordonnee.complements != '' || this.coordonnee.boitePostale != '' || this.coordonnee.codePostal != '' || this.coordonnee.ville != '' || this.paysId != 0 || this.coordonnee.premierURL != '' || this.coordonnee.deuxiemeURL != '') {
            this.coordonneesService.addCoordonneeOrganisation(this.coordonnee, organisation.id, this.paysId).then(coord => {
            });
          }
          // enregistrer societe dans la base d'intranet
          this.organisationService.importerOrganisationIntranet(organisation.id, 0).then((ancienIdSoc) => {
            // insérer l'id d'intranet dans ancienId d'escalnet
            this.organisationService.addAncienIdOrganisation(Number(ancienIdSoc), organisation.id).then((retour) => {
              // CHANGEMENTS PAR RAPPORT A AUTRES SOCIETES
              // modifier client en rajoutant l'ancien id de la société
              this.organisationService.addAncienIdClient(Number(ancienIdSoc), this.clientId).then((client) => {
              });
              // modifier societe en rajoutant l'id du client
              this.organisationService.addClientOrganisation(this.clientId, organisation.id).then((client) => {
              });
              // modifier client en rajoutant le dirigeant de la société
              if (this.dirigeantId != 0) {
                  this.clientService.addDirigeantClient(this.clientId, dirigeantNomPrenom).then((client) => {
                  });
              }
              // rappeler la fonction, cette fois pour s'assurer que les promises soient terminés
              this.organisationService.importerOrganisationIntranet(organisation.id, Number(ancienIdSoc)).then((fin) => {
              });
            });
            // on crée l'association Membre
            this.clientService.addMembre(this.clientId).then(membre => {
              // importer dans la base intranet
              this.clientService.addMembreIntranet(Number(ancienIdSoc)).then();
            });
          });
        });
      } else {
        this.dialogueShareService.addDialogue('Le genre est obligatoire');
      }
    } else {
      this.dialogueShareService.addDialogue('Le nom est obligatoire');
    }
  }

}
