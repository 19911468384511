import {CategoriesGenerales} from '../information/CategoriesGenerales';
import {TypeRetombee} from './TypeRetombee';
import {TypeAnnonce} from './TypeAnnonce';
import {Support} from '../support/Support';
import {Client} from '../client/Client';
import {Format} from './Format';
import {Suivi} from '../suivi/Suivi';
import {Mailling} from '../mailling/Mailling';
import {CampagneParution} from './CampagneParution';
import {AuthUser} from '../user/authUser';
import {ParutionContact} from './ParutionContact';
import {FichiersParution} from './FichiersParution';
import {PhotoParution} from './PhotoParution';


export class Parution {
  id: number;
  nom: string;
  date: Date;
  resume: string;
  qualification: string;
  commentaire: string;
  nbImpression: number;
  creation: Date;
  modification: Date;
  coefficient: string;
  theme: CategoriesGenerales;
  typeRetombee: TypeRetombee;
  typeAnnonce: TypeAnnonce;
  support: Support;
  client: Client;
  format: Format;
  suiteItw: Suivi;
  suiteSuivi: Suivi;
  suiteCommunique: Mailling;
  campagne: CampagneParution;
  auteur: AuthUser;
  fichiersParutions: Array<FichiersParution>;
  parutionContacts: Array<ParutionContact>;
  ancienId: number;
  contenu: string;
  lien: string;
  photoParution: PhotoParution;

  constructor(id: number, nom: string, date: Date, resume: string, qualification: string, commentaire: string, nbImpression: number,
              creation: Date, modification: Date, coefficient: string, theme: CategoriesGenerales, typeRetombee: TypeRetombee,
              typeAnnonce: TypeAnnonce, support: Support, client: Client, format: Format, suiteItw: Suivi, suiteSuivi: Suivi,
              suiteCommunique: Mailling, campagne: CampagneParution, auteur: AuthUser, fichiersParutions: Array<FichiersParution>,
              parutionContacts: Array<ParutionContact>, ancienId: number, contenu: string, lien: string, photoParution: PhotoParution) {
    this.id = id;
    this.nom = nom;
    this.date = date;
    this.resume = resume;
    this.qualification = qualification;
    this.commentaire = commentaire;
    this.nbImpression = nbImpression;
    this.creation = creation;
    this.modification = modification;
    this.coefficient = coefficient;
    this.theme = theme;
    this.typeRetombee = typeRetombee;
    this.typeAnnonce = typeAnnonce;
    this.support = support;
    this.client = client;
    this.format = format;
    this.suiteItw = suiteItw;
    this.suiteSuivi = suiteSuivi;
    this.suiteCommunique = suiteCommunique;
    this.campagne = campagne;
    this.auteur = auteur;
    this.fichiersParutions = fichiersParutions;
    this.parutionContacts = parutionContacts;
    this.ancienId = ancienId;
    this.contenu = contenu;
    this.lien = lien;
    this.photoParution = photoParution;
  }
}
