import {Component, Input, OnInit} from '@angular/core';
import {ParutionService} from '../../../../service/interne/parution/parutionService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-escal-parution-fiche-intranet',
  templateUrl: './escal-parution-fiche-intranet.component.html',
  styleUrls: ['./escal-parution-fiche-intranet.component.scss'],
  providers: [ ParutionService ]
})
export class EscalParutionFicheIntranetComponent implements OnInit {

  @Input()
  private sub: any;
  parution: any;
  parutionId: number;
  constructor(public formatIntranetShareService: FormatIntranetShareService, private parutionService: ParutionService,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.parutionId = params['id'];
      // récupérer toutes les informations de cette parution
      this.parutionService.getParutionIntranet(this.parutionId).then((parution) => {
          this.parution = parution;
          if (this.parution != undefined) {
            this.parution.nom = this.parution.nom.replace('&amp;', '&');
            this.parution.nom = this.parution.nom.replace(' &#039;', ' ’');
            this.parution.nom = this.parution.nom.replace('&#039;', '’');
            this.parution.nom = this.parution.nom.replace('L&#039;', 'L’');
            this.parution.nom = this.parution.nom.replace('l&#039;', 'l’');
            this.parution.nom = this.parution.nom.replace(' &quot;', ' "');
            this.parution.nom = this.parution.nom.replace('&quot;', '"');
            this.parution.resume = this.parution.resume.replace('&amp;', '&');
            this.parution.resume = this.parution.resume.replace(' &#039;', ' ’');
            this.parution.resume = this.parution.resume.replace('&#039;', '’');
            this.parution.resume = this.parution.resume.replace('L&#039;', 'L’');
            this.parution.resume = this.parution.resume.replace('l&#039;', 'l’');
            this.parution.resume = this.parution.resume.replace(' &quot;', ' "');
            this.parution.resume = this.parution.resume.replace('&quot;', '"');
            if (this.parution.date != null && this.parution.date != '0000-00-00' && this.parution.date != '') {
              this.parution.date = new Date(this.parution.date);
            }
            if (this.parution.created != null && this.parution.created != '0000-00-00' && this.parution.created != '') {
              this.parution.created = new Date(this.parution.created);
            }
            if (this.parution.updated != null && this.parution.updated != '0000-00-00' && this.parution.updated != '') {
              this.parution.updated = new Date(this.parution.updated);
            }
          }
        });
    });
  }

}
