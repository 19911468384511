import {Component, Inject, Injectable, OnInit} from '@angular/core';
import {Client} from '../../../../model/client/Client';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {ParutionService} from '../../../../service/interne/parution/parutionService';
import {Mailling} from '../../../../model/mailling/Mailling';
import {MaillingService} from '../../../../service/interne/mailling/maillingService';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {Format} from '../../../../model/parution/Format';
import {TypeRetombee} from '../../../../model/parution/TypeRetombee';
import {TypeAnnonce} from '../../../../model/parution/TypeAnnonce';
import {CampagneParution} from '../../../../model/parution/CampagneParution';
import {Parution} from '../../../../model/parution/Parution';
import {DiversParution} from '../../../../model/parution/DiversParution';
import {AuthShareService} from '../../../../service/partage/AuthShareService';
import {Support} from '../../../../model/support/Support';
import {Contact} from '../../../../model/contact/Contact';
import {Router} from '@angular/router';

@Component({
  selector: 'app-escal-parution-add',
  templateUrl: './escal-parution-add.component.html',
  styleUrls: ['./escal-parution-add.component.scss'],
  providers: [ ClientService, ParutionService, MaillingService ],
})
export class EscalParutionAddComponent implements OnInit {

  clients: Array<Client>;
  clientId: number;
  formatId: number;
  supportId: number;
  themeId: number;
  typeRetombeeId: number;
  typeAnnonceId: number;
  cpId: number;
  itwId: number;
  suiviId: number;
  campagneId: number;
  qualification: string;
  champRechercheSupport: string;
  afficheSupport: boolean;
  afficheITW: boolean;
  afficheSuivi: boolean;
  affiche: boolean;
  champRechercheContact = '';
  champRechercheInterview = '';
  champRechercheSuivi = '';
  rechercheEncours = false;
  rechercheEncoursSupport = false;
  rechercheEncoursInterview = false;
  rechercheEncoursSuivi = false;
  supportsRecherche: Array<any> = [];
  interviewsRecherche: Array<any> = [];
  suivisRecherche: Array<any> = [];
  contactsRecherche: Array<any> = [];
  listesChoix: Array<any> = [];
  contacts: Array<any> = [];
  listes: Array<any> = [];
  idContacts: Array<any> = [];
  idListes: Array<any> = [];
  idContactsRecherche: Array<any> = [];
  idListesChoix: Array<any> = [];
  contactSelect: any ;
  listeSelect: any ;
  communiques: Array<Mailling>;
  client: Client;
  themes: Array<any> = [];
  campagnes: Array<any> = [];
  formats: Array<Format>;
  typeRetombees: Array<TypeRetombee>;
  typeAnnonces: Array<TypeAnnonce>;
  campagne: string;
  parution: Parution;
  divers: DiversParution;
  contactsIds: Array<any> = [];
  listesIds: Array<any> = [];
  cree: boolean;
  creer: boolean;
  parutionId: number;
  constructor(private clientService: ClientService, public formatIntranetShareService: FormatIntranetShareService,
              private parutionService: ParutionService, private maillingService: MaillingService,
              private dialogueShareService: DialogueShareService, private authShareService: AuthShareService,
              private router: Router) { }

  ngOnInit() {
    this.clientId = 0;
    this.cree = false;
    this.creer = false;
    this.parutionId = 0;
    this.formatId = 0;
    this.supportId = 0;
    this.themeId = 0;
    this.typeRetombeeId = 0;
    this.typeAnnonceId = 0;
    this.cpId = 0;
    this.itwId = 0;
    this.suiviId = 0;
    this.campagneId = 0;
    this.qualification = '';
    this.champRechercheSupport = '';
    this.afficheSupport = false;
    this.afficheITW = false;
    this.afficheSuivi = false;
    this.affiche = false;
    this.campagne = '';
    this.parution = new Parution(0, '', null, '', '', '', 0, new Date() , null, '',
      null, null, null, null, null, null, null, null, null, null,
      this.authShareService.authUser, [], [], null, '', '', null);
    this.divers = new DiversParution(0, null, false, false, false, false, false, false, false,
      false, false, false, false, false, '', false, false, '', false,
      false, false, false, true);
    this.clientService.getClientList(1)
      .then((clients) => {
        this.clients = clients['hydra:member'] as Array<Client>;
      });
    this.parutionService.getFormats()
      .then((formats) => {
        this.formats = formats['hydra:member'] as Array<Format>;
      });
    this.parutionService.getTypesRetombees()
      .then((typeRetombees) => {
        this.typeRetombees = typeRetombees['hydra:member'] as Array<TypeRetombee>;
      });
    this.parutionService.getTypesAnnonces()
      .then((typeAnnonces) => {
        this.typeAnnonces = typeAnnonces['hydra:member'] as Array<TypeAnnonce>;
      });
    this.parutionService.getCampagnesParutions()
      .then((campagnes) => {
        this.campagnes = campagnes['hydra:member'] as Array<CampagneParution>;
      });
    this.parutionService.getListesContacts().then((listesContacts) => {
      this.listesChoix = listesContacts;
      this.listesChoix.forEach(liste => {
        if (liste.nom != '' && liste.nom != null) {
          liste.nom = liste.nom.replace('&amp;', '&');
          liste.nom = liste.nom.replace('&#039;', '’');
          liste.nom = liste.nom.replace('L&#039;', 'L’');
          liste.nom = liste.nom.replace('Ã©', 'é');
          liste.nom = liste.nom.replace('Ã¨', 'è');
          liste.nom = liste.nom.replace('Ã¢', 'â');
          liste.nom = liste.nom.replace('Ãª', 'ê');
          liste.nom = liste.nom.replace('Ã§', 'ç');
          liste.nom = liste.nom.replace('Ã¹', 'ù');
          liste.nom = liste.nom.replace('Ã', 'à');
        }
      });
    });
  }

  rechercheContact() {
    this.contactsRecherche = [];
    this.affiche = true;
    if (this.champRechercheContact.length > 2 && !this.rechercheEncours) {
      this.rechercheEncours = true;
      this.champRechercheContact = this.champRechercheContact.replace(' ', '.');
      this.parutionService.getContactRecherche(this.champRechercheContact).then((retour) => {
        this.contactsRecherche = retour;
        this.champRechercheContact = this.champRechercheContact.replace('.', ' ');
      });
      this.rechercheEncours = false;
    }
  }

  rechercheSupport() {
    this.supportsRecherche = [];
    this.supportId = 0 ;
    this.afficheSupport = true;
    if (this.champRechercheSupport.length > 2 && !this.rechercheEncoursSupport) {
      this.rechercheEncoursSupport = true;
      this.champRechercheSupport = this.champRechercheSupport.replace(' ', '.');
      this.parutionService.getSupportRecherche(this.champRechercheSupport).then((retour) => {
        this.supportsRecherche = retour;
        this.supportsRecherche.forEach(support => {
          if (support.nom != '' && support.nom != null) {
            // remplacer caractères reçus de l'api
            support.nom = support.nom.replace('&amp;', '&');
            support.nom = support.nom.replace('&#039;', '’');
            support.nom = support.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSupport = this.champRechercheSupport.replace('.', ' ');
      });
      this.rechercheEncoursSupport = false;
    }
  }

  rechercheInterview() {
    // si client renseigné
    if (this.clientId != 0) {
      this.interviewsRecherche = [];
      this.itwId = 0 ;
      this.afficheITW = true;
      if (this.champRechercheInterview.length > 2 && !this.rechercheEncoursInterview) {
        this.rechercheEncoursInterview = true;
        this.champRechercheInterview = this.champRechercheInterview.replace(' ', '.');
        this.clientService.getClientDetail(this.clientId).then((client) => {
          this.client = client as Client;
          this.parutionService.getInterviewsRecherche(this.champRechercheInterview, this.client.ancienId).then((retour) => {
            this.interviewsRecherche = retour;
            this.interviewsRecherche.forEach(itw => {
              if (itw.theme != '' && itw.theme != null) {
                // remplacer caractères reçus de l'api
                itw.theme = itw.theme.replace('&amp;', '&');
                itw.theme = itw.theme.replace('&#039;', '’');
                itw.theme = itw.theme.replace('L&#039;', 'L’');
              }
              if (itw.support != '' && itw.support != null) {
                // remplacer caractères reçus de l'api
                itw.support = itw.support.replace('&amp;', '&');
                itw.support = itw.support.replace('&#039;', '’');
                itw.support = itw.support.replace('L&#039;', 'L’');
              }
              if (itw.contact != '' && itw.contact != null) {
                // remplacer caractères reçus de l'api
                itw.contact = itw.contact.replace('&amp;', '&');
                itw.contact = itw.contact.replace('&#039;', '’');
                itw.contact = itw.contact.replace('L&#039;', 'L’');
              }
            });
            this.champRechercheInterview = this.champRechercheInterview.replace('.', ' ');
          });
          this.rechercheEncoursInterview = false;
        });
      }
    } else {
      this.dialogueShareService.addDialogue('Veuillez renseigner le client');
    }
  }

  rechercheSuivi() {
    // si client renseigné
    if (this.clientId != 0) {
      // si support renseigné
      if (this.supportId != 0) {
        this.suivisRecherche = [];
        this.suiviId = 0 ;
        this.afficheSuivi = true;

        if (this.champRechercheSuivi.length > 2 && !this.rechercheEncoursSuivi) {
          this.rechercheEncoursSuivi = true;
          this.champRechercheSuivi = this.champRechercheSuivi.replace(' ', '.');
          this.clientService.getClientDetail(this.clientId).then((client) => {
            this.client = client as Client;
            this.parutionService.getSuivisRecherche(this.champRechercheSuivi, this.client.ancienId, this.supportId).then((retour) => {
              this.suivisRecherche = retour;
              this.suivisRecherche.forEach(suivi => {
                if (suivi.titre != '' && suivi.titre != null) {
                  // remplacer caractères reçus de l'api
                  suivi.titre = suivi.titre.replace('&amp;', '&');
                  suivi.titre = suivi.titre.replace('&#039;', '’');
                  suivi.titre = suivi.titre.replace('L&#039;', 'L’');
                }
                if (suivi.contact != '' && suivi.contact != null) {
                  // remplacer caractères reçus de l'api
                  suivi.contact = suivi.contact.replace('&amp;', '&');
                  suivi.contact = suivi.contact.replace('&#039;', '’');
                  suivi.contact = suivi.contact.replace('L&#039;', 'L’');
                }
              });
              this.champRechercheSuivi = this.champRechercheSuivi.replace('.', ' ');
            });
            this.rechercheEncoursSuivi = false;
          });
        }
      } else {
        this.dialogueShareService.addDialogue('Veuillez renseigner le support');
      }
    } else {
      this.dialogueShareService.addDialogue('Veuillez renseigner le client');
    }
  }

  changeClient() {
    this.cpId = 0;
    this.interviewsRecherche = [];
    this.suivisRecherche = [];
    this.clientService.getClientDetail(this.clientId).then((client) => {
      this.client = client as Client;
      this.parutionService.getInterviewsClient(this.client.ancienId).then((retour) => {
        this.interviewsRecherche = retour;
        this.interviewsRecherche.forEach(itw => {
          if (itw.theme != '' && itw.theme != null) {
            // remplacer caractères reçus de l'api
            itw.theme = itw.theme.replace('&amp;', '&');
            itw.theme = itw.theme.replace('&#039;', '’');
            itw.theme = itw.theme.replace('L&#039;', 'L’');
          }
          if (itw.support != '' && itw.support != null) {
            // remplacer caractères reçus de l'api
            itw.support = itw.support.replace('&amp;', '&');
            itw.support = itw.support.replace('&#039;', '’');
            itw.support = itw.support.replace('L&#039;', 'L’');
          }
          if (itw.contact != '' && itw.contact != null) {
            // remplacer caractères reçus de l'api
            itw.contact = itw.contact.replace('&amp;', '&');
            itw.contact = itw.contact.replace('&#039;', '’');
            itw.contact = itw.contact.replace('L&#039;', 'L’');
          }
        });
      });
      if (this.supportId != 0 && this.clientId != 0) {
        this.parutionService.getSuivisClient(this.client.ancienId, this.supportId).then((retour) => {
          this.suivisRecherche = retour;
          this.suivisRecherche.forEach(suivi => {
            if (suivi.titre != '' && suivi.titre != null) {
              // remplacer caractères reçus de l'api
              suivi.titre = suivi.titre.replace('&amp;', '&');
              suivi.titre = suivi.titre.replace('&#039;', '’');
              suivi.titre = suivi.titre.replace('L&#039;', 'L’');
            }
            if (suivi.contact != '' && suivi.contact != null) {
              // remplacer caractères reçus de l'api
              suivi.contact = suivi.contact.replace('&amp;', '&');
              suivi.contact = suivi.contact.replace('&#039;', '’');
              suivi.contact = suivi.contact.replace('L&#039;', 'L’');
            }
          });
        });
      }
      // récupérer thèmes du client
      this.clientService.getThemesClient(this.client.ancienId).then((retour) => {
        this.themes = retour;
        if (this.themes.length > 0) {
          this.themeId = this.themes[0].id;
        }
        this.themes.forEach(theme => {
          if (theme.libelle != '' && theme.libelle != null) {
            theme.libelle = theme.libelle.replace('&amp;', '&');
            theme.libelle = theme.libelle.replace('&#039;', '’');
            theme.libelle = theme.libelle.replace('L&#039;', 'L’');
            theme.libelle = theme.libelle.replace('Ã©', 'é');
            theme.libelle = theme.libelle.replace('Ã¨', 'è');
            theme.libelle = theme.libelle.replace('Ã¢', 'â');
            theme.libelle = theme.libelle.replace('Ãª', 'ê');
            theme.libelle = theme.libelle.replace('Ã§', 'ç');
            theme.libelle = theme.libelle.replace('Ã¹', 'ù');
            theme.libelle = theme.libelle.replace('Ã', 'à');
          }
        });
      });
    });
    this.maillingService.getMaillingListClient(this.clientId).then((communiques) => {
      this.communiques = communiques['hydra:member'] as Array<Mailling>;
      this.communiques.forEach(comm => {
        // récupérer nom des supports
        if (comm.nom !== '' && comm.nom !== null) {
          comm.nom = comm.nom.replace('&#039;', '’');
        }
      });
    });
  }

  changeSupport() {
    this.suivisRecherche = [];
    if (this.supportId != 0 && this.clientId != 0) {
      this.parutionService.getSuivisClient(this.client.ancienId, this.supportId).then((retour) => {
        this.suivisRecherche = retour;
        this.suivisRecherche.forEach(suivi => {
          if (suivi.titre != '' && suivi.titre != null) {
            // remplacer caractères reçus de l'api
            suivi.titre = suivi.titre.replace('&amp;', '&');
            suivi.titre = suivi.titre.replace('&#039;', '’');
            suivi.titre = suivi.titre.replace('L&#039;', 'L’');
          }
          if (suivi.contact != '' && suivi.contact != null) {
            // remplacer caractères reçus de l'api
            suivi.contact = suivi.contact.replace('&amp;', '&');
            suivi.contact = suivi.contact.replace('&#039;', '’');
            suivi.contact = suivi.contact.replace('L&#039;', 'L’');
          }
        });
      });
    }
  }

  setSelected(selectElement, action, nature) {
    const groupe: Array<any> = [];
    let optionSelected;
    for (let i = 0; i < selectElement.options.length; i++) {
      if (selectElement.options[i].selected) {
        optionSelected = selectElement.options[i].value;
        // Le format de value est de [0: '1']
        if (optionSelected.indexOf('\'') !== -1) {
          // extraire le vrai id sauvegarder
          optionSelected = optionSelected.indexOf('\'');
          optionSelected = selectElement.options[i].value.slice(optionSelected);
          optionSelected = this.extraitNombre(optionSelected);
          optionSelected = optionSelected.toString();
        }
        groupe.push(optionSelected);
      }
    }
    if (action === 'add') {
      switch (nature) {
        case 'contact':
          this.idContacts = groupe;
          break;
        case 'liste':
          this.idListes = groupe;
          break;
      }
    } else if (action === 'delete') {
      switch (nature) {
        case 'contact':
          this.idContactsRecherche = groupe;
          break;
        case 'liste':
          this.idListesChoix = groupe;
          break;
      }
    }
  }

  extraitNombre(str) {
    return Number(str.replace(/\D/g, ''));
  }

  changeContact(action) {
    let listeContact;
    let contactPresent;
    let contactSave;
    if (action === 'add') {
      listeContact = this.idContacts;
    } else {
      listeContact = this.idContactsRecherche;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeContact.forEach((idContactsSelectionnes) => {
      this.contactsRecherche.forEach(contact => {
        if (idContactsSelectionnes == contact.id) {
          this.contactSelect = contact ;
          this.contactsRecherche =
            this.contactsRecherche.filter(contSave => contSave.id !== idContactsSelectionnes);
        } else {
          // retirer le(es) contact(s) sélectionné(s) dans la liste à sauvegarder
          this.contacts = this.contacts.filter(contSave => contSave.id !== idContactsSelectionnes);
        }
      });
      if (action === 'add') {
        // retirer le(es) contact(s) sélectionné(s) dans la liste de base
        // évite les doublons
        contactPresent = this.contacts.find((cont) => cont.id === this.contactSelect.id);
        if (contactPresent === undefined) {
          this.contacts.push(this.contactSelect);
        }
      } else if (action === 'delete') {
        // évite les doublons
        contactPresent = this.contactsRecherche.find((cont) => cont.id == this.contactSelect.id);
        contactSave = this.contacts.find(cont => cont.id == this.contactSelect.id);
        if (contactPresent === undefined && contactSave === undefined) {
          this.contactsRecherche.push(this.contactSelect);
        }
      }
    });
  }

  changeListe(action) {
    let listeListe;
    let listePresent;
    let listeSave;
    if (action === 'add') {
      listeListe = this.idListes;
    } else {
      listeListe = this.idListesChoix;
    }
    // Gestion de la liste à sauvegarder et la liste de selection
    listeListe.forEach((idListesSelectionnes) => {
      this.listesChoix.forEach(liste => {
        if (idListesSelectionnes == liste.id) {
          this.listeSelect = liste ;
          this.listesChoix =
            this.listesChoix.filter(listeS => listeS.id !== idListesSelectionnes);
        } else {
          // retirer le(es) contact(s) sélectionné(s) dans la liste à sauvegarder
          this.listes = this.listes.filter(listeS => listeS.id !== idListesSelectionnes);
        }
      });
      if (action === 'add') {
        // retirer la(es) liste(s) sélectionnée(s) dans la liste de base
        // évite les doublons
        listePresent = this.listes.find((list) => list.id === this.listeSelect.id);
        if (listePresent === undefined) {
          this.listes.push(this.listeSelect);
        }
      } else if (action === 'delete') {
        // évite les doublons
        listePresent = this.listesChoix.find((list) => list.id == this.listeSelect.id);
        listeSave = this.listes.find(li => li.id == this.listeSelect.id);
        if (listePresent === undefined && listeSave === undefined) {
          this.listesChoix.push(this.listeSelect);
        }
      }
    });
  }

  changeCampagne() {
    this.campagneId = 0;
  }

  changeCampagneId() {
    this.campagne = '';
  }

  creerParution() {
    // si informations obligatoires non renseignées
    if (this.parution.nom != '') {
      if (this.parution.date != null) {
        if (this.clientId != 0) {
          if (this.themeId != 0) {
            // empeche la creation de multiple parution
            this.cree = true;
            // ajouter parution
            this.parutionService.addParution(this.parution, this.clientId, this.formatId, this.typeRetombeeId, this.typeAnnonceId)
              .then(parution => {
                // si nouvelle campagne renseignée
                if (this.campagne != '') {
                  // on crée la nouvelle campagne
                  this.parutionService.addCampagneParution(this.campagne).then(campagne => {
                    // on lie la campagne
                    this.parutionService.addCampagne(campagne.id, parution.id).then(cmp => {
                      // on l'importe dans l'intranet
                      this.parutionService.importerIntranetCampagne(campagne.id).then();
                    });
                  });
                }
                // si campagne déjà existante renseignée
                if (this.campagneId != 0) {
                  // on lie la campagne
                  this.parutionService.addCampagne(this.campagneId, parution.id).then();
                }
                // si thème renseigné
                if (this.themeId != 0) {
                  // on importe le thème
                  this.parutionService.importerTheme(this.themeId).then(() => {
                    // on récupère le thème renseigné
                    this.parutionService.getThemeByAncienId(this.themeId).then((theme) => {
                      const themeId = theme['hydra:member'][0].id;
                      // on l'assigne à la nouvelle parution
                      this.parutionService.addThemeParution(themeId, parution.id).then(() => {
                      });
                    });
                  });
                }
                // si communiqué renseigné
                if (this.cpId != 0) {
                  // on lie le communiqué
                  this.parutionService.addCommunique(this.cpId, parution.id).then();
                }
                // si support renseigné
                if (this.supportId != 0) {
                  // on importe le support
                  this.parutionService.importerSupport(this.supportId).then(() => {
                    // on récupère le support renseigné
                    this.parutionService.getSupportByAncienId(this.supportId).then((support) => {
                      const supportId = support['hydra:member'][0].id;
                      // on l'assigne à la nouvelle parution
                      this.parutionService.addSupportParution(supportId, parution.id).then(() => {
                      });
                    });
                  });
                }
                let contactIdsSelect = '';
                let contactId = 0;
                // envoi les id des auteurs sous forme de chaine de caractère
                this.contacts.forEach( contact => {
                  contactId = contact.id;
                  contactIdsSelect = contactIdsSelect + contactId + ',';
                  // si auteur(s) à associer à au moins une liste de contact
                  let listeId = 0;
                  if (this.listes.length != 0) {
                    this.listes.forEach( liste => {
                      listeId = liste.id;
                      this.parutionService.addAuteurListe(contactId, listeId).then(() => {
                      });
                    });
                  }
                });
                // si auteur(s) renseigné(s)
                if (contactIdsSelect != '') {
                  // on importe le(s) contact(s)
                  this.parutionService.importerAuteurs(contactIdsSelect).then((contacts) => {
                    const tabContacts = contacts.contacts.split(',');
                    // pour chaque contact, on le récupère et on l'associe à la parution
                    tabContacts.forEach( cont => {
                      if (cont != '') {
                        this.parutionService.addContactParution(cont, parution.id).then(() => {
                        });
                      }
                    });
                  });
                }
                // si suite itw renseignée
                if (this.itwId != 0) {
                  // on importe le suivi interview si pas déjà dans l'escalnet
                  this.parutionService.importerSuivi(this.itwId).then(() => {
                    // on récupère le suivi interview renseigné
                    this.parutionService.getSuiviByAncienId(this.itwId).then((itw) => {
                      console.log(itw);
                      const interviewId = itw['hydra:member'][0].id;
                      // on l'assigne à la nouvelle parution
                      this.parutionService.addInterviewParution(interviewId, parution.id).then(() => {
                      });
                    });
                  });
                }
                // si suite suivi renseigné
                if (this.suiviId != 0) {
                  // on importe le suivi si pas déjà dans l'escalnet
                  this.parutionService.importerSuivi(this.suiviId).then(() => {
                    // on récupère le suivi renseigné
                    this.parutionService.getSuiviByAncienId(this.suiviId).then((suiv) => {
                      const suivId = suiv['hydra:member'][0].id;
                      // on l'assigne à la nouvelle parution
                      this.parutionService.addSuiviParution(suivId, parution.id).then(() => {
                      });
                    });
                  });
                }
                // importer checkbox et details de la parution dans une table à part
                this.parutionService.addDiversParution(this.divers, parution.id)
                  .then(divers => {
                    // enregistrer parution dans la base d'intranet
                    this.parutionService.importerParutionIntranet(parution.id, 0).then((ancienIdSoc) => {
                      // insérer l'id d'intranet dans ancienId d'escalnet
                      this.parutionService.addAncienIdParution(Number(ancienIdSoc), parution.id).then((retour) => {
                        // rappeler la fonction, cette fois pour s'assurer que les promises soient terminés
                        this.parutionService.importerParutionIntranet(parution.id, Number(ancienIdSoc)).then((fin) => {
                          this.dialogueShareService.addDialogue('La parution a bien été créée');
                          this.parutionId = parution.id;
                          this.creer = true;
                        });
                      });
                    });
                  });
              });
          } else {
            this.dialogueShareService.addDialogue('Le thème est obligatoire');
          }
        } else {
          this.dialogueShareService.addDialogue('Le client est obligatoire');
        }
      } else {
        this.dialogueShareService.addDialogue('La date est obligatoire');
      }
    } else {
      this.dialogueShareService.addDialogue('Le titre est obligatoire');
    }
  }

}
