import { Component, OnInit } from '@angular/core';
import {AuthUser} from '../../../../model/user/authUser';
import {AuthUserService} from '../../../../service/interne/user/AuthUserService';
import {Client} from '../../../../model/client/Client';
import {ClientService} from '../../../../service/interne/client/ClientService';
import {TypeRetombee} from '../../../../model/parution/TypeRetombee';
import {TypeAnnonce} from '../../../../model/parution/TypeAnnonce';
import {ParutionService} from '../../../../service/interne/parution/parutionService';
import {OrganisationService} from '../../../../service/interne/organisation/OrganisationService';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {SupportService} from '../../../../service/interne/support/SupportService';
import {TypeMedia} from '../../../../model/support/TypeMedia';
import {Famille} from '../../../../model/support/Famille';
import {Periodicite} from '../../../../model/support/Periodicite';
import {ZoneDiffusion} from '../../../../model/support/ZoneDiffusion';
import {Mailling} from '../../../../model/mailling/Mailling';
import {MaillingService} from '../../../../service/interne/mailling/maillingService';
import {CampagneParution} from '../../../../model/parution/CampagneParution';
import {Parution} from '../../../../model/parution/Parution';

@Component({
  selector: 'app-escal-parution-recherche',
  templateUrl: './escal-parution-recherche.component.html',
  styleUrls: ['./escal-parution-recherche.component.scss'],
  providers: [AuthUserService, ClientService, ParutionService, OrganisationService, SupportService, MaillingService]
})
export class EscalParutionRechercheComponent implements OnInit {

  rechercheEnCours: boolean;
  periodeDeb: Date;
  periodeFin: Date;
  suiviPar: number;
  jours: number;
  creeChoisi: string;
  users: Array<AuthUser> = [];
  clients: Array<Client>;
  clientId: number;
  typeRetombeeId: number;
  typeAnnonceId: number;
  typeMediaId: number;
  typePresseId: number;
  cpId: number;
  typeRetombees: Array<TypeRetombee>;
  typeAnnonces: Array<TypeAnnonce>;
  typeMedias: Array<TypeMedia>;
  typePresses: Array<Famille>;
  periodicites: Array<Periodicite>;
  zoneDiffusions: Array<ZoneDiffusion>;
  categId: number;
  afficheCateg: boolean;
  categoriesRecherche: Array<any> = [];
  contactsRecherche: Array<any> = [];
  champRechercheCateg: string;
  champRechercheContact: string;
  rechercheEncoursCateg = false;
  rechercheEncoursContact = false;
  rechercheEncoursSupport = false;
  contactId: number;
  supportId: number;
  periodiciteId: number;
  zoneDiffId: number;
  afficheContact: boolean;
  afficheSupport: boolean;
  nom: string;
  champRechercheSupport: string;
  listes: Array<any> = [];
  supportsRecherche: Array<any> = [];
  communiques: Array<Mailling>;
  campagnes: Array<any> = [];
  campagneId: number;
  parutionsRecherchesAvancees: Array<Parution>;
  headerContent: Array<any> = [];
  curentPage = 1;
  maxPage: number;
  total: number;
  constructor(private authUserService: AuthUserService, private clientService: ClientService, private parutionService: ParutionService,
              private organisationService: OrganisationService, public formatIntranetShareService: FormatIntranetShareService,
              private supportService: SupportService, private maillingService: MaillingService) { }

  ngOnInit() {
    this.headerContent.push('isParution');
    this.headerContent.push('Nom');
    this.headerContent.push('Client');
    this.headerContent.push('Support');
    this.headerContent.push('Date');

    this.rechercheEnCours = false;
    this.afficheCateg = false;
    this.afficheContact = false;
    this.afficheSupport = false;
    this.nom = '';
    this.clientId = 0;
    this.suiviPar = 0;
    this.jours = 0;
    this.typeAnnonceId = 0;
    this.typeRetombeeId = 0;
    this.typeMediaId = 0;
    this.typePresseId = 0;
    this.periodiciteId = 0;
    this.zoneDiffId = 0;
    this.categId = 0;
    this.supportId = 0;
    this.contactId = 0;
    this.cpId = 0;
    this.campagneId = 0;
    this.creeChoisi = '';
    this.champRechercheCateg = '';
    this.champRechercheContact = '';
    this.champRechercheSupport = '';
    this.communiques = [];
    this.parutionService.getTypesRetombees()
      .then((typeRetombees) => {
        this.typeRetombees = typeRetombees['hydra:member'] as Array<TypeRetombee>;
      });
    this.parutionService.getTypesAnnonces()
      .then((typeAnnonces) => {
        this.typeAnnonces = typeAnnonces['hydra:member'] as Array<TypeAnnonce>;
      });
    this.supportService.getTypesMedias()
      .then((typeMedias) => {
        this.typeMedias = typeMedias['hydra:member'] as Array<TypeMedia>;
      });
    this.supportService.getTypesPresses()
      .then((typePresses) => {
        this.typePresses = typePresses['hydra:member'] as Array<Famille>;
      });
    this.supportService.getPeriodicites()
      .then((peridicites) => {
        this.periodicites = peridicites['hydra:member'] as Array<Periodicite>;
      });
    this.supportService.getZoneDiffusions()
      .then((zoneDiffusions) => {
        this.zoneDiffusions = zoneDiffusions['hydra:member'] as Array<ZoneDiffusion>;
      });
    this.authUserService.getUsersList(1).then((usersRetour) => {
      this.users = usersRetour['hydra:member'] as Array<AuthUser>;
    });
    this.clientService.getClientList(1)
      .then((clients) => {
        this.clients = clients['hydra:member'] as Array<Client>;
      });
    this.parutionService.getCampagnesParutions()
      .then((campagnes) => {
        this.campagnes = campagnes['hydra:member'] as Array<CampagneParution>;
      });
    this.parutionService.getListesParutions().then((listesparutions) => {
      this.listes = listesparutions;
      this.listes.forEach(liste => {
        if (liste.nom != '' && liste.nom != null) {
          liste.nom = liste.nom.replace('&amp;', '&');
          liste.nom = liste.nom.replace('&#039;', '’');
          liste.nom = liste.nom.replace('L&#039;', 'L’');
          liste.nom = liste.nom.replace('Ã©', 'é');
          liste.nom = liste.nom.replace('Ã¨', 'è');
          liste.nom = liste.nom.replace('Ã¢', 'â');
          liste.nom = liste.nom.replace('Ãª', 'ê');
          liste.nom = liste.nom.replace('Ã§', 'ç');
          liste.nom = liste.nom.replace('Ã¹', 'ù');
          liste.nom = liste.nom.replace('Ã', 'à');
        }
      });
    });
  }


  rechercheCategories() {
    this.afficheCateg = true;
    if (this.champRechercheCateg.length > 2 && !this.rechercheEncoursCateg) {
      this.rechercheEncoursCateg = true;
      this.champRechercheCateg = this.champRechercheCateg.replace(' ', '.');
      this.organisationService.getCategoriesRecherche(this.champRechercheCateg).then((retour) => {
        this.categoriesRecherche = retour;
        this.categoriesRecherche.forEach(categorie => {
          if (categorie.nom != '' && categorie.nom != null) {
            categorie.nom = categorie.nom.replace('&amp;', '&');
            categorie.nom = categorie.nom.replace('&#039;', '’');
            categorie.nom = categorie.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheCateg = this.champRechercheCateg.replace('.', ' ');
      });
      this.rechercheEncoursCateg = false;
    }
  }


  rechercheContact() {
    this.afficheContact = true;
    if (this.champRechercheContact.length > 2 && !this.rechercheEncoursContact) {
      this.rechercheEncoursContact = true;
      this.champRechercheContact = this.champRechercheContact.replace(' ', '.');
      this.organisationService.getDirigeantRecherche(this.champRechercheContact).then((retour) => {
        this.contactsRecherche = retour;
        this.contactsRecherche.forEach(dirigeant => {
          if (dirigeant.nomPrenom != '' && dirigeant.nomPrenom != null) {
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&amp;', '&');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('&#039;', '’');
            dirigeant.nomPrenom = dirigeant.nomPrenom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheContact = this.champRechercheContact.replace('.', ' ');
      });
      this.rechercheEncoursContact = false;
    }
  }


  rechercheSupport() {
    this.supportsRecherche = [];
    this.supportId = 0 ;
    this.afficheSupport = true;
    if (this.champRechercheSupport.length > 2 && !this.rechercheEncoursSupport) {
      this.rechercheEncoursSupport = true;
      this.champRechercheSupport = this.champRechercheSupport.replace(' ', '.');
      this.parutionService.getSupportRecherche(this.champRechercheSupport).then((retour) => {
        this.supportsRecherche = retour;
        this.supportsRecherche.forEach(support => {
          if (support.nom != '' && support.nom != null) {
            // remplacer caractères reçus de l'api
            support.nom = support.nom.replace('&amp;', '&');
            support.nom = support.nom.replace('&#039;', '’');
            support.nom = support.nom.replace('L&#039;', 'L’');
          }
        });
        this.champRechercheSupport = this.champRechercheSupport.replace('.', ' ');
      });
      this.rechercheEncoursSupport = false;
    }
  }

  changeClient() {
    this.cpId = 0;
    this.maillingService.getMaillingListClient(this.clientId).then((communiques) => {
      this.communiques = communiques['hydra:member'] as Array<Mailling>;
      this.communiques.forEach(comm => {
        // récupérer nom des supports
        if (comm.nom !== '' && comm.nom !== null) {
          comm.nom = comm.nom.replace('&#039;', '’');
        }
      });
    });
  }

  rechercherParutions() {
    this.parutionService.getParutionsRechercheAvancee(this.nom, this.clientId, this.typeRetombeeId, this.typeAnnonceId,
      this.categId, this.contactId, this.supportId, this.typeMediaId, this.typePresseId, this.periodiciteId,
      this.zoneDiffId, this.cpId, this.campagneId, this.suiviPar, this.periodeDeb, this.periodeFin, this.creeChoisi,
      this.jours, this.curentPage).then((retour) => {
      this.parutionsRecherchesAvancees = retour['hydra:member'] as Array<Parution>;
      this.total = retour['hydra:totalItems'];
      this.getMaxPage(retour['hydra:totalItems']);
      this.rechercheEnCours = true;
    });
  }


  getMaxPage(nbElement) {
    this.maxPage = Math.trunc(nbElement / 10);
    if (nbElement % 10 !== 0) {
      this.maxPage = this.maxPage + 1;
    }
  }

  changePage(page) {
    this.curentPage = page;
    this.rechercherParutions();
  }

  reprendreRecherche() {
    this.rechercheEnCours = false;
    this.curentPage = 1;
  }

}
