import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Environement} from '../../../environement/environement';


@Injectable()
export class LogoSupportService {
  private logoSupportUrl = Environement.BASE_API_URL + '/api/logo_supports';
  constructor(private http: HttpClient) { };

  addLogo(idSupport, photoUpload): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.logoSupportUrl , {'idSupport': idSupport, 'url' : idSupport.toString() + '.' + photoUpload['extension'],
        'value': photoUpload['value'], 'filetype': photoUpload['extension']},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  deleteLogo(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.delete(this.logoSupportUrl + '/' + id , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;}
}
