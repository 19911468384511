import { Injectable } from '@angular/core';


import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';
import {removeAccents} from '../../partage/prototype/sansAccents';
import {Client} from '../../../model/client/Client';


@Injectable()
export class ClientService {
    private clientUrl = Environement.BASE_API_URL + '/api/clients';
    private motsClesUrl = Environement.BASE_API_URL + '/api/mots_cles';
    private membreUrl = Environement.BASE_API_URL + '/api/membres';
    constructor(private http: HttpClient) { }

  getClientList(actuel) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '?pagination=false&actuel=' + actuel + '&order[nom]', {headers} ).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
    }
    getClient(page, actuel): Promise<any> {
      const headers = this.initOptionHeader();
      return this.http.get(this.clientUrl + '?_page=' + page + '&actuel=' + actuel + '&order[id]=desc', {headers} ).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
    }
    getClientDetail(id): Promise<any> {
      const headers = this.initOptionHeader();
      return this.http.get(this.clientUrl + '/' + id , {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }

    changerStatut(client: Client) {
      let etat = null;
      if (client.actuel == false) {
        etat =  Environement.BASE_API_URL + '/api/etats/' + 2;
      } else {
        etat =  Environement.BASE_API_URL + '/api/etats/' + 1;
      }
      const headers = this.initOptionHeader();
      return this.http.put(this.clientUrl + '/' +  client.id, { 'actuel': client.actuel, 'etat': etat},
        {headers: headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }

  modifierClientOVH(client: Client, adresseGenerique, adresseCoupure) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/' +  client.id + '/modifier/' + adresseGenerique + '/' + adresseCoupure,
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

    modifierInfos(client: Client, etatId) {
      const headers = this.initOptionHeader();
      let etat = null;
      if (etatId != 0) {
        etat = Environement.BASE_API_URL + '/api/etats/' + etatId;
      }
      if (etatId == 2 || etatId == 5 || etatId == 6 || etatId == 7) {
        console.log(etatId);
        return this.http.put(this.clientUrl + '/' +  client.id, { 'nom': client.nom, 'dirigeant': client.dirigeant, 'ancienId': client.ancienId,
            adresseGenerique: client.adresseGenerique, adresseCoupure: client.adresseCoupure, 'etat': etat, 'actuel': false},
          {headers: headers} ).toPromise()
          .then(response => response)
          .catch(this.errorHandler);
      } else {
        return this.http.put(this.clientUrl + '/' +  client.id, { 'nom': client.nom, 'dirigeant': client.dirigeant, 'ancienId': client.ancienId,
            adresseGenerique: client.adresseGenerique, adresseCoupure: client.adresseCoupure, 'etat': etat, 'actuel': true},
          {headers: headers} ).toPromise()
          .then(response => response)
          .catch(this.errorHandler);
      }
    }

    getClientsRecherche(recherche: Array<any>, page, actuel) {
        const content = recherche[0];
        let type = recherche[1];
        const headers = this.initOptionHeader();
        type = removeAccents(type.toLowerCase());
        if (type === 'tous') {
            return this.http.get(this.clientUrl + '?several_all=' + content + '&_page=' + page + '&actuel=' + actuel + '&order[nom]',
                {headers} ).toPromise()
                .then(response => response)
                .catch(this.errorHandler);
        }
        return this.http.get(this.clientUrl + '?' + type + '=' + content + '&_page=' + page + '&actuel=' + actuel + '&order[nom]',
            {headers} ).toPromise()
            .then(response => response)
            .catch(this.errorHandler);
    }

  getClientsRechercheMotsCles(recherche: Array<any>) {
    const content = recherche[0];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    return this.http.get(this.motsClesUrl + '?several_all=' + content ,
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
  }

  rechercheClientsScore(score, actuel) {
    score = score.toUpperCase();
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/scoreClients/score/' + score + '/' + actuel ,
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getClientsDates(dateDebut, dateFin, actuel) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/rechercheDates/' + dateDebut + '/' + dateFin + '/' + actuel , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getClientsDatesTri(dateDebut, dateFin, actuel, typeTri) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/rechercheDatesTri/' + dateDebut + '/' + dateFin + '/' + actuel + '/' + typeTri , {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  addClient(client: Client, etatId) {
      let etat = null;
      if (etatId != 0) {
        etat = Environement.BASE_API_URL + '/api/etats/' + etatId;
      }
      let actuel = true ;
      if (etatId === 2 || etatId === 5 || etatId === 6 || etatId === 7) {
        actuel = false;
      }
    const headers = this.initOptionHeader();
    return this.http.post(this.clientUrl , {nom: client.nom, adresseGenerique: client.adresseGenerique,
      adresseCoupure: client.adresseCoupure, 'actuel': actuel, ancienId: client.ancienId, dirigeant: client.dirigeant, 'etat': etat },
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateMotsCles(client: Client, motCleID, action) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/' +  client.id + '/' + motCleID + '/' +  action, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  updateClientMotsCles(client: Client) {
    let dateEntree;
    let dateSortie;
    let dateDebutOk;
    let dateFinOk;
    dateDebutOk = new Date(client.dateEntree.getTime() + (2 * 1000 * 60 * 60));
    dateFinOk = new Date(client.dateSortie.getTime() + (2 * 1000 * 60 * 60));
    dateEntree = dateDebutOk.getFullYear() + '-' + (dateDebutOk.getMonth() + 1).toString() + '-' + dateDebutOk.getDate() + 'T00:00:00';
    dateSortie = dateFinOk.getFullYear() + '-' + (dateFinOk.getMonth() + 1).toString() + '-' + dateFinOk.getDate() + 'T00:00:00';
    const headers = this.initOptionHeader();
    return this.http.put(this.clientUrl + '/' + client.id , {score: client.score,
        duree: client.duree, dateSortie: dateSortie, dateEntree: dateEntree}
        , { headers} ).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }
  rechercheClients(motsClesList, actuel) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/motsClesList/recherche/' +  motsClesList + '/' + actuel , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addDirigeantClient(clientId, dirigeant) {
    const headers = this.initOptionHeader();
    return this.http.put(this.clientUrl + '/' +  clientId, {'dirigeant': dirigeant},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getListeContactRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/recherche/intranet/liste/contact/coupures/' + recherche, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getListeItwArticleRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/recherche/intranet/liste/interview/article/' + recherche, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }


  associerListeContactClient(listeId, clientAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/associer/intranet/liste/contact/coupures/' + listeId + '/' + clientAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  majListeItwArticle(listeId, clientAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/update/intranet/liste/interview/article/' + listeId + '/' + clientAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getAssoClientListeContact(clientAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/recuperer/intranet/liste/contact/coupures/' + clientAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getThemesClient(clientAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/recuperer/intranet/themes/client/' + clientAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  associerThemeClient(themeId, clientAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/associer/intranet/theme/client/' + themeId + '/' + clientAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  desassocierThemesClient(themesId, clientAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/desassocier/intranet/theme/client/' + themesId + '/' + clientAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  exporterRapportParutions(clientAncienId, dateDeb, dateFin) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/statistique/exportation/' + clientAncienId + '/' + dateDeb + '/' + dateFin, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getClientAnalyses(clientAncienId, dateDebut, dateFin) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/statistiques/intranet/analyses/client/' + clientAncienId + '/' + dateDebut + '/' + dateFin, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  exporterAnalyses(clientAncienId, dateDebut, dateFin) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/statistiques/intranet/analyses/client/exporter/' + clientAncienId + '/' + dateDebut + '/' + dateFin, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getClientRA(clientAncienId, dateDebut, dateFin) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/statistiques/intranet/rapport/activites/client/' + clientAncienId + '/' + dateDebut + '/' + dateFin, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getClientRAWord(clientAncienId, dateDebut, dateFin, format, suivis, citation, date, photo, commentaires, type, nature, audience, details, suiteItw, suite, suiteCP, top, themes): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.clientUrl + '/rapport/activites/client/generer/word', {'id': clientAncienId, 'dateDebut': dateDebut, 'dateFin': dateFin,
        'format': format, 'suivis': suivis, 'citation': citation, 'date': date, 'photo': photo, 'commentaires': commentaires, 'type': type, 'nature': nature,
        'audience': audience, 'details': details, 'suiteItw': suiteItw, 'suite': suite, 'suiteCP': suiteCP, 'top': top, 'themes': themes},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getIdSuivi(suiviAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/trouver/suivi/escalnet/' + suiviAncienId , {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getMembresClient(clientId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.membreUrl + '?client.id=' + clientId, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addMembre(clientId) {
    const headers = this.initOptionHeader();
    return this.http.post(this.membreUrl, {'client': Environement.BASE_API_URL + '/api/clients/' + clientId},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addMembreIntranet(clientAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/ajouter/info/client/intranet/' + clientAncienId , {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  modifierMembre(membreId, premierMembreId, deuxiemeMembreId, troisiemeMembreId) {
    const headers = this.initOptionHeader();
    let premierMembre = null;
    let deuxiemeMembre = null;
    let troisiemeMembre = null;
    if (premierMembreId != 0) {
      premierMembre = Environement.BASE_API_URL + '/api/users/' + premierMembreId;
    }
    if (deuxiemeMembreId != 0) {
      deuxiemeMembre = Environement.BASE_API_URL + '/api/users/' + deuxiemeMembreId;
    }
    if (troisiemeMembreId != 0) {
      troisiemeMembre = Environement.BASE_API_URL + '/api/users/' + troisiemeMembreId;
    }
    return this.http.put(this.membreUrl + '/' +  membreId, { 'premierMembre': premierMembre,
      'deuxiemeMembre': deuxiemeMembre, 'troisiemeMembre': troisiemeMembre },
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  modifierMembreIntranet(clientAncienId, premierMembreId, deuxiemeMembreId, troisiemeMembreId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.clientUrl + '/modifier/membres/intranet/' + clientAncienId + '/' + premierMembreId + '/'
      + deuxiemeMembreId + '/' + troisiemeMembreId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

    private errorHandler(err: any): Promise<any> {
        return Promise.reject(err.message || err );
    }
    private initOptionHeader() {
        const token: any = localStorage.getItem('auth-tokens');
        let headers = new HttpHeaders().set('Content-Type', 'application/json');
        headers = headers.set('X-Auth-Token', token);
        return headers;
    }
}
