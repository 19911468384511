import { Injectable } from '@angular/core';

import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Environement} from '../../../environement/environement';
import {removeAccents} from '../../partage/prototype/sansAccents';


@Injectable()
export class ParutionService {
    private pressbookUrl = Environement.BASE_API_URL + '/parutions';
    private parutionUrl = Environement.BASE_API_URL + '/api/parutions';
    private typeRetombeeURL = Environement.BASE_API_URL + '/api/type_retombees';
    private typeAnnonceURL = Environement.BASE_API_URL + '/api/type_annonces';
    private formatURL = Environement.BASE_API_URL + '/api/formats';
    private campagneURL = Environement.BASE_API_URL + '/api/campagne_parutions';
    private diversParutionURL = Environement.BASE_API_URL + '/api/divers_parutions';
    constructor(private http: HttpClient) { }

  getInfoSommaire(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.pressbookUrl + '/infoSommaire/' + id, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  genererSommaire(id, policeChose, trierClient, trierTypeParution, inclureTitreParution,
                  listeTypeParution,  repriseCp, orderDateCroissant, couleur, couleurPrint, couleurAudiovisuel, couleurOnline,
                  couleurAgence, trierLogoSupport, listeLogoSupport): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.pressbookUrl + '/genererSommaire', {'id': id, 'police': policeChose, 'trierClient': trierClient,
        'trierTypeParution': trierTypeParution, 'inclureTitreParution': inclureTitreParution, 'listeTypeParution': listeTypeParution,
      'repriseCp': repriseCp, 'orderDateRecentLast': orderDateCroissant, 'couleur': couleur, 'couleurPrint': couleurPrint,
        'couleurAudiovisuel': couleurAudiovisuel, 'couleurOnline': couleurOnline, 'couleurAgence': couleurAgence,
      'trierLogoSupport': trierLogoSupport, 'listeLogoSupport': listeLogoSupport},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }
  getPressBookGenerer(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.pressbookUrl + '/getLink/' + id, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getContactRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recherche/intranet/contact/' + recherche, {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSupportRecherche(recherche) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recherche/intranet/support/' + recherche, {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getInterviewsRecherche(recherche, clientAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recherche/intranet/interview/' + recherche + '/' + clientAncienId, {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getInterviewsClient(clientAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recuperer/intranet/interview/' + clientAncienId, {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSuivisRecherche(recherche, clientAncienId, supportAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recherche/intranet/suivi/' + recherche + '/' + clientAncienId + '/' + supportAncienId, {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSuivisClient(clientAncienId, supportAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recuperer/intranet/suivi/' + clientAncienId + '/' + supportAncienId, {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getCampagnesConcurrents() {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recuperer/intranet/campagnes/concurrents', {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getListesContacts() {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recuperer/intranet/listes/contacts', {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getListesParutions() {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recuperer/intranet/listes/parutions', {headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getTypesRetombees() {
    const headers = this.initOptionHeader();
    return this.http.get(this.typeRetombeeURL, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getTypesAnnonces() {
    const headers = this.initOptionHeader();
    return this.http.get(this.typeAnnonceURL, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getFormats() {
    const headers = this.initOptionHeader();
    return this.http.get(this.formatURL, {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getCampagnesParutions() {
    const headers = this.initOptionHeader();
    return this.http.get(this.campagneURL + '?order[libelle]=asc', {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addCampagneParution(campagne) {
    const headers = this.initOptionHeader();
    return this.http.post(this.campagneURL, {'libelle': campagne},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addParution(parution, clientId, formatId, typeRetombeeId, typeAnnonceId) {
    let Date = null ;
    let format = null;
    let typeRetombee = null;
    let typeAnnonce = null;
    if (parution.date != null) {
      Date = parution.date.getFullYear() + '-' + (parution.date.getMonth() + 1).toString() + '-' + parution.date.getDate() + ' 00:00:00' ;
    }
    const DateCre = parution.creation.getFullYear() + '-' + (parution.creation.getMonth() + 1).toString() + '-' + parution.creation.getDate() + ' ' + parution.creation.getHours() + ':' + parution.creation.getMinutes() + ':00' ;
    if (formatId != 0) {
      format = Environement.BASE_API_URL + '/api/formats/' + formatId;
    }
    if (typeRetombeeId != 0) {
      typeRetombee = Environement.BASE_API_URL + '/api/type_retombees/' + typeRetombeeId;
    }
    if (typeAnnonceId != 0) {
      typeAnnonce = Environement.BASE_API_URL + '/api/type_annonces/' + typeAnnonceId;
    }
    const headers = this.initOptionHeader();
    return this.http.post(this.parutionUrl, {'nom': parution.nom, 'client': Environement.BASE_API_URL + '/api/clients/' + clientId,
      'resume': parution.resume, 'qualification': parution.qualification, 'commentaire': parution.commentaire,
        'coefficient': parution.coefficient, 'date': Date, 'creation' : DateCre, 'format': format, 'typeRetombee': typeRetombee,
      'typeAnnonce': typeAnnonce, 'auteur': Environement.BASE_API_URL + '/api/auth_users/' + parution.auteur.id, 'contenu': '', 'lien': '',
      'photoPrincipale': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addDiversParution(divers, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.post(this.diversParutionURL, {'parution': Environement.BASE_API_URL + '/api/parutions/' + parutionId,
        'photo': divers.photo, 'illustration': divers.illustration, 'logo': divers.logo, 'urlClient': divers.urlClient,
      'agenda': divers.agenda, 'graphique': divers.graphique, 'tableau': divers.tableau, 'nomination': divers.nomination,
      'courrierLecteur': divers.courrierLecteur, 'mailing': divers.mailing, 'mailCree': divers.mailCree, 'imprimer': divers.imprimer,
      'bestOfClient': divers.bestOfClient, 'bestOfGeneral': divers.bestOfGeneral, 'lienRS': divers.lienRS, 'paruFb': divers.paruFb,
      'paruTw': divers.paruTw, 'paruFbEscal': divers.paruFbEscal, 'paruTwEscal': divers.paruTwEscal, 'imprimante': divers.imprimante,
      'pressroom': divers.pressroom},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
}

  addCampagne(campagneId, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'campagne': Environement.BASE_API_URL + '/api/campagne_parutions/' + campagneId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  importerIntranetCampagne(campagneId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/campagne/intranet/creer/' + campagneId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  importerTheme(themeId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/theme/intranet/importer/' + themeId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getThemeByAncienId(themeId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/categories_generales?ancienId=' + themeId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addThemeParution(themeId, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'theme': Environement.BASE_API_URL + '/api/categories_generales/' + themeId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addCommunique(cpId, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'suiteCommunique': Environement.BASE_API_URL + '/api/maillings/' + cpId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  importerSupport(supportId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/support/intranet/importer/' + supportId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSupportByAncienId(supportId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/supports?ancienId=' + supportId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addSupportParution(supportId, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'support': Environement.BASE_API_URL + '/api/supports/' + supportId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  importerAuteurs(contactIdsSelect) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/auteurs/intranet/importer/tout/' + contactIdsSelect, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addContactParution(contactId, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.post(Environement.BASE_API_URL + '/api/parution_contacts', {
        'contact': Environement.BASE_API_URL + '/api/contacts/' + contactId,
        'parution': Environement.BASE_API_URL + '/api/parutions/' + parutionId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addAuteurListe(contactId, listeId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/associer/intranet/contact/liste/' + contactId + '/' + listeId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  importerSuivi(suiviId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/suivi/intranet/importer/tout/' + suiviId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSuiviByAncienId(suiviId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/suivis?ancienId=' + suiviId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addInterviewParution(interviewId, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'suiteItw': Environement.BASE_API_URL + '/api/suivis/' + interviewId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addSuiviParution(suiviId, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'suiteSuivi': Environement.BASE_API_URL + '/api/suivis/' + suiviId},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  importerParutionIntranet(parutionId, ancienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/importation/copie/parution/' + parutionId + '/' + ancienId,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addAncienIdParution(ancienIdParution, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'ancienId': ancienIdParution},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getParutionsParDefaut(page, jour) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl +  '?parution_defaut=' + jour + '&_page=' + page + '&order[date]=desc', {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getParutionsRecherche(recherche: Array<any>, page) {
    const content = recherche[0] + '~' + recherche[2];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    if (type === 'tous') {
      return this.http.get(this.parutionUrl + '?several_all=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'nom') {
      return this.http.get(this.parutionUrl + '?parution_nom=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'client') {
      return this.http.get(this.parutionUrl + '?parution_client=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'support') {
      return this.http.get(this.parutionUrl + '?parution_support=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'resume') {
      return this.http.get(this.parutionUrl + '?parution_resume=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'auteur') {
      return this.http.get(this.parutionUrl + '?parution_auteur=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  getParutionDetails(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getParutionContacts(parutionId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/parution_contacts?parution.id=' + parutionId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getAncienIdAuteur(parutionId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/auteur/intranet/' + parutionId , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getDiversParution(parutionId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/divers_parutions?parution.id=' + parutionId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getAllFichiersParution(parutionId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/fichiers_parutions?parution.id=' + parutionId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  supprimerAssoFichiersParution(fichierId) {
    const headers = this.initOptionHeader();
    return this.http.delete(Environement.BASE_API_URL + '/api/fichiers_parutions/' + fichierId,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerAssoContactsParution(assocontactID) {
    const headers = this.initOptionHeader();
    return this.http.delete(Environement.BASE_API_URL + '/api/parution_contacts/' + assocontactID,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerDiversParution(diversId) {
    const headers = this.initOptionHeader();
    return this.http.delete(Environement.BASE_API_URL + '/api/divers_parutions/' + diversId,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerParution(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.parutionUrl + '/' +  id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerImportParutionIntranet(ancienIdParution) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/importation/supprimer/copie/parution/' + ancienIdParution ,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getListeParutionRecherche(recherche, parutionAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recherche/intranet/liste/parution/' + recherche + '/' + parutionAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  associerListeParution(listeId, parutionAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/associer/intranet/liste/parution/' + listeId + '/' + parutionAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getListesParution(parutionAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recuperer/intranet/listes/parution/' + parutionAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  desassocierListeParution(listesId, parutionAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/desassocier/intranet/liste/parution/' + listesId + '/' + parutionAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  associerParutionMailing(diversId, associer) {
    const headers = this.initOptionHeader();
    return this.http.put(this.diversParutionURL + '/' +  diversId, {'mailing': associer},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  associerMailingIntranet(parutionAncienId, associer) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/associer/intranet/mailing/parution/' + parutionAncienId + '/' + associer, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  exporterParution(parutionId, id, titre, date, doc, resume, theme, qualification, format, typeRetombee, suitecp, campagne, typeAnnonce,
                   pressroom, photo, illustration, logo, urlClient, agenda, graphique, nomination, tableau, courrier,
                   commentaire, auteur, support, supportid, client, clientid, auteurs, auteursid, suiteSuivi, suiteSuiviid) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/statistique/exportation/parution/' + parutionId + '/' + id + '/' + titre
      + '/' + date + '/' + doc + '/' + resume + '/' + theme + '/' + qualification + '/' + format + '/' + typeRetombee + '/' + suitecp
      + '/' + campagne + '/' + typeAnnonce + '/' + pressroom + '/' + photo + '/' + illustration + '/' + logo + '/' + urlClient + '/' +
      agenda + '/' + graphique + '/' + nomination + '/' + tableau + '/' + courrier + '/' + commentaire + '/' + auteur + '/' + support +
      '/' + supportid + '/' + client + '/' + clientid + '/' + auteurs + '/' + auteursid + '/' + suiteSuivi + '/' + suiteSuiviid,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }


  getParutionsRechercheAvancee(nom, clientId, typeRetombeeId, typeAnnonceId, categId, contactId, supportId, typeMediaId, typePresseId, periodiciteId, zoneDiffId, cpId, campagneId, suiviPar, periodeDeb, periodeFin, creeChoisi, jours, page) {
    let headers = this.initOptionHeader();
    let url = '';
    // customiser url de recherche en fonction des champs renseignés
    if (nom != '') {
      url = url + '&nom=' + nom ;
    }
    if (clientId != 0 && cpId == 0) {
      url = url + '&client.id=' + clientId ;
    }
    if (typeRetombeeId != 0) {
      url = url + '&typeRetombee.id=' + typeRetombeeId ;
    }
    if (typeAnnonceId != 0) {
      url = url + '&typeAnnonce.id=' + typeAnnonceId ;
    }
    if (categId != 0) {
      url = url + '&theme.ancienId=' + categId ;
    }
    if (contactId != 0) {
      url = url + '&parutionContacts.contact.ancienId=' + contactId ;
    }
    if (supportId != 0) {
      url = url + '&support.ancienId=' + supportId ;
    }
    if (typeMediaId != 0) {
      url = url + '&support.typeMedia.id=' + typeMediaId ;
    }
    if (typePresseId != 0) {
      url = url + '&support.famille.id=' + typePresseId ;
    }
    if (periodiciteId != 0) {
      url = url + '&support.periodicite.id=' + periodiciteId ;
    }
    if (zoneDiffId != 0) {
      url = url + '&support.zoneDiffusion.id=' + zoneDiffId ;
    }
    if (cpId != 0) {
      url = url + '&suiteCommunique.id=' + cpId ;
    }
    if (campagneId != 0) {
      url = url + '&campagne.id=' + campagneId ;
    }
    if (periodeDeb) {
      periodeDeb = periodeDeb.getFullYear() + '-' + (periodeDeb.getMonth() + 1) + '-' + periodeDeb.getDate() + ' 00:00:00' ;
      url = url + '&date[after]=' + periodeDeb ;
    }
    if (periodeFin) {
      periodeFin = periodeFin.getFullYear() + '-' + (periodeFin.getMonth() + 1) + '-' + periodeFin.getDate() + ' 23:59:59' ;
      url = url + '&date[before]=' + periodeFin ;
    }
    if (suiviPar != 0) {
      url = url + '&auteur.id=' + suiviPar ;
    }
    if (creeChoisi != '' && jours != 0) {
      const dateActuelle = new Date();
      dateActuelle.setDate(dateActuelle.getDate() - jours );
      const dateActu = dateActuelle.getFullYear() + '-' + (dateActuelle.getMonth() + 1) + '-' + dateActuelle.getDate() + ' '
        + dateActuelle.getHours() + ':' + dateActuelle.getMinutes() + ':00' ;
      if (creeChoisi == 'plus') {
        // il y a plus de X jours
        url = url + '&date[before]=' + dateActu ;
      }
      if (creeChoisi == 'moins') {
        // il y a moins de X jours
        url = url + '&date[after]=' + dateActu ;
      }
    }
    // supprimer le premier &
    url = url.substr(1);
    return this.http.get(this.parutionUrl + '?' + url + '&_page=' + page + '&order[date]=desc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  addParutionCopie(parution, clientId, formatId, typeRetombeeId, typeAnnonceId) {
    let format = null;
    let typeRetombee = null;
    let typeAnnonce = null;
    let nom = '';
    nom = 'Copie de ' + parution.nom;
    if (formatId != 0 && formatId != null) {
      format = Environement.BASE_API_URL + '/api/formats/' + formatId;
    }
    if (typeRetombeeId != 0 && typeRetombeeId != null) {
      typeRetombee = Environement.BASE_API_URL + '/api/type_retombees/' + typeRetombeeId;
    }
    if (typeAnnonceId != 0 && typeAnnonceId != null) {
      typeAnnonce = Environement.BASE_API_URL + '/api/type_annonces/' + typeAnnonceId;
    }
    const headers = this.initOptionHeader();
    return this.http.post(this.parutionUrl, {'nom': nom, 'client': Environement.BASE_API_URL + '/api/clients/' + clientId,
        'resume': parution.resume, 'qualification': parution.qualification, 'commentaire': parution.commentaire,
        'coefficient': parution.coefficient, 'date': parution.date, 'creation' : parution.creation, 'format': format, 'typeRetombee': typeRetombee,
        'typeAnnonce': typeAnnonce, 'auteur': Environement.BASE_API_URL + '/api/auth_users/' + parution.auteur.id, 'contenu': '', 'lien': '',
        'photoPrincipale': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  inclurePressroom(divers) {
    const headers = this.initOptionHeader();
    if (divers.pressroom == true) {
      return this.http.put(this.diversParutionURL + '/' +  divers.id, {'pressroom' : false},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.put(this.diversParutionURL + '/' +  divers.id, {'pressroom' : true},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }


  inclurePressroomIntranet(parutionAncienId, parutionId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/inclure/pressroom/parution/' + parutionAncienId + '/' + parutionId,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }


  envoyerParutionReseauxSociaux(parutionAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/coupure/envoyer/reseauxsociaux/' + parutionAncienId,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }


  getParutionsRechercheIntranet(recherche, type) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recherche/intranet/' + recherche + '/' + type , {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getParutionIntranet(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recherche/fiche/intranet/' + id , {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  addPhoto(parutionId, photoPrincipale) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'photoPrincipale': photoPrincipale},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  deletePhoto(parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'photoPrincipale': ''},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  modifierParution(parution, clientId, formatId, typeRetombeeId, typeAnnonceId) {
    let dateCre = null ;
    let format = null;
    let typeRetombee = null;
    let typeAnnonce = null;
    if (parution.date != null) {
      dateCre = parution.date ;
    }
    parution.modification = new Date();
    const DateModif = parution.modification.getFullYear() + '-' + (parution.modification.getMonth() + 1).toString() + '-' + parution.modification.getDate() + ' ' + parution.modification.getHours() + ':' + parution.modification.getMinutes() + ':00' ;
    if (formatId != 0) {
      format = Environement.BASE_API_URL + '/api/formats/' + formatId;
    }
    if (typeRetombeeId != 0) {
      typeRetombee = Environement.BASE_API_URL + '/api/type_retombees/' + typeRetombeeId;
    }
    if (typeAnnonceId != 0) {
      typeAnnonce = Environement.BASE_API_URL + '/api/type_annonces/' + typeAnnonceId;
    }
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parution.id, {'nom': parution.nom, 'client': Environement.BASE_API_URL + '/api/clients/' + clientId,
        'resume': parution.resume, 'qualification': parution.qualification, 'commentaire': parution.commentaire,
        'coefficient': parution.coefficient, 'date': dateCre, 'modification' : DateModif, 'format': format, 'typeRetombee': typeRetombee,
        'typeAnnonce': typeAnnonce, 'contenu': parution.contenu, 'lien': parution.lien},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  modifierDiversParution(divers) {
    const headers = this.initOptionHeader();
    return this.http.put(this.diversParutionURL + '/' +  divers.id, {
        'photo': divers.photo, 'illustration': divers.illustration, 'logo': divers.logo, 'urlClient': divers.urlClient,
        'agenda': divers.agenda, 'graphique': divers.graphique, 'tableau': divers.tableau, 'nomination': divers.nomination,
        'courrierLecteur': divers.courrierLecteur, 'mailing': divers.mailing, 'mailCree': divers.mailCree, 'imprimer': divers.imprimer,
        'bestOfClient': divers.bestOfClient, 'bestOfGeneral': divers.bestOfGeneral, 'lienRS': divers.lienRS, 'paruFb': divers.paruFb,
        'paruTw': divers.paruTw, 'paruFbEscal': divers.paruFbEscal, 'paruTwEscal': divers.paruTwEscal, 'imprimante': divers.imprimante,
        'pressroom': divers.pressroom},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  retirerCampagne(parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'campagne': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  retirerCommunique(parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'suiteCommunique': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  retirerSupport(parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'support': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  retirerInterview(parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'suiteItw': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  retirerSuivi(parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(this.parutionUrl + '/' +  parutionId, {'suiteSuivi': null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getAllParutionsClient(clientId, page) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '?client.id=' + clientId + '&_page=' + page + '&order[date]=desc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  genererPdfParution(parutionId, contenu, lien, supportId, titre, date, photoPrincipale, parutionAncienId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.post(this.parutionUrl + '/article/client/generer/pdf', {'id': parutionId, 'contenu': contenu, 'lien': lien,
        'supportId': supportId, 'titre': titre, 'date': date, 'photoPrincipale': photoPrincipale, 'parutionAncienId': parutionAncienId},
      {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  getLogoSupportIntranet(supportAncienId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '/recuperer/logo/support/intranet/' + supportAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getParutionByAncienId(parutionAncienId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.parutionUrl + '?ancienId=' + parutionAncienId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader() {
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;
    }
}
