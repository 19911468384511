import { Component, OnInit } from '@angular/core';
import {Suivi} from '../../../../model/suivi/Suivi';
import {SuiviActionShareService} from '../../../../service/partage/SuiviActionShareService';
import {ActivatedRoute, Router} from '@angular/router';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';
import {ClientMailingCalendrierRedactionnel} from '../../../../model/client/ClientMailingCalendrierRedactionnel';
import {SuiviNature} from '../../../../model/suivi/SuiviNature';
import {DialogueShareService} from '../../../../service/partage/utilitaire/DialogueShareService';
import {Parution} from '../../../../model/parution/Parution';

@Component({
  selector: 'app-escal-suivi-fiche',
  templateUrl: './escal-suivi-fiche.component.html',
  styleUrls: ['./escal-suivi-fiche.component.scss']
})
export class EscalSuiviFicheComponent implements OnInit {

  private sub: any;
  suivi: Suivi;
  suiviNature: SuiviNature;
  premiereAsso: ClientMailingCalendrierRedactionnel;
  deuxiemeAsso: ClientMailingCalendrierRedactionnel;
  verifRelance: boolean;
  rappelId: number;
  constructor(private route: ActivatedRoute, private suiviActionShareService: SuiviActionShareService,
              public formatIntranetShareService: FormatIntranetShareService, private dialogueShareService: DialogueShareService,
              private router: Router) { }

  ngOnInit() {
    const that = this;
    this.rappelId = 0;
    this.sub = this.route.params.subscribe(params => {
      this.suiviActionShareService.getSuiviDetails(params['id'])
        .then((suivi) => {
          that.suivi = suivi as Suivi;
          that.suivi.suiviNatures.forEach(suiviNature => {
            that.suiviNature = suiviNature;
          });
          that.suivi.clientMailingCalendrierRedactionnels.reverse();
          that.premiereAsso = that.suivi.clientMailingCalendrierRedactionnels[0];
          that.deuxiemeAsso = that.suivi.clientMailingCalendrierRedactionnels[1];
          let user = this.suivi.suiviPar.user.prenom + ' ' + this.suivi.suiviPar.user.nom;
          user = user.replace(' ', '.');
          this.suiviActionShareService.verifierRelanceIntranet(user, this.suivi.contact.ancienId)
            .then(verif => {
              if (verif != 0) {
                this.verifRelance = true;
                this.rappelId = verif;
              } else {
                this.verifRelance = false;
              }
            });
        });
    });
  }

  supprimerSuivi() {
    this.dialogueShareService.addDialogue('Supprimer le suivi ?', () => {
      // supprimer les associations avant de supprimer le suivi
      this.suiviActionShareService.supprimerSuiviNature(this.suiviNature.id).then((suiviNature) => {
        this.suiviActionShareService.recupererParutionSuivi(this.suivi.id).then((suiviParutions) => {
          const parutionsSuivi = suiviParutions['hydra:member'] as Array<Parution>;
          parutionsSuivi.forEach( parutionSuivi => {
            // mettre à jour le suivi de la parution à null
            this.suiviActionShareService.suppParutionSuivi(parutionSuivi.id).then();
          });
          this.suiviActionShareService.recupererParutionInterview(this.suivi.id).then((itwParutions) => {
            const parutionsItw = itwParutions['hydra:member'] as Array<Parution>;
            parutionsItw.forEach(parutionItw => {
              // mettre à jour l'itw de la parution à null
              this.suiviActionShareService.suppParutionInterview(parutionItw.id).then();

            });
            this.suiviActionShareService.supprimerClientMailingCalRed(this.premiereAsso.id).then((clientMailCalRed) => {
              // si le suivi a une deuxieme societe cliente concernée
              if (this.deuxiemeAsso) {
                this.suiviActionShareService.supprimerClientMailingCalRed(this.deuxiemeAsso.id).then((clientMailCalRed2) => {
                  // supprimer suivi dans la base intranet
                  this.suiviActionShareService.supprimerImportSuiviIntranet(this.suivi.id)
                    .then(() => {
                      this.suiviActionShareService.supprimerSuivi(this.suivi.id).then((suivi) => {
                        this.router.navigate(['/escal/suivis']);
                        this.dialogueShareService.addDialogue('Le suivi a bien été supprimé');
                      });
                    });
                });
              } else {
                // si le suivi n'a qu'une societe cliente concernée
                // supprimer suivi dans la base intranet
                this.suiviActionShareService.supprimerImportSuiviIntranet(this.suivi.id)
                  .then(() => {
                    this.suiviActionShareService.supprimerSuivi(this.suivi.id).then((suivi) => {
                      this.router.navigate(['/escal/suivis']);
                      this.dialogueShareService.addDialogue('Le suivi a bien été supprimé');
                    });
                  });
              }
            });
          });
        });
      });
    });
  }

  changeInclureRA() {
    let mediaIdsSelect = '';
    this.suiviActionShareService.inclureRA(this.suivi).then(incRA => {
      this.suivi.supports.forEach(support => {
        mediaIdsSelect = mediaIdsSelect + support.id + ',';
      });
      // gérer le cas sans supports
      if (mediaIdsSelect == '') {
        mediaIdsSelect = ',';
      }
      // supprimer suivi dans la base intranet
      this.suiviActionShareService.supprimerImportSuiviIntranet(this.suivi.id)
        .then(() => {
          // enregistrer suivi dans la base d'intranet
          this.suiviActionShareService.importerSuiviIntranet(this.suivi.id, mediaIdsSelect)
            .then();
        });
      this.ngOnInit();
    });
  }

}
