import {Injectable} from '@angular/core';


import {DialogueShareService} from './utilitaire/DialogueShareService';
import {Suivi} from '../../model/suivi/Suivi';
import {AuthUser} from '../../model/user/authUser';
import {Contact} from '../../model/contact/Contact';
import {TypeRelationSuivi} from '../../model/suivi/TypeRelationSuivi';
import {VecteurSuivi} from '../../model/suivi/VecteurSuivi';
import {Statut} from '../../model/suivi/Statut';
import {Support} from '../../model/support/Support';
import {SuiviNature} from '../../model/suivi/SuiviNature';
import {Environement} from '../../environement/environement';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {removeAccents} from './prototype/sansAccents';





@Injectable()
export class SuiviActionShareService {
  private statutURL = Environement.BASE_API_URL + '/api/statut_suivis';
  private vecteurURL = Environement.BASE_API_URL + '/api/vecteur_suivis';
  private natureUrl = Environement.BASE_API_URL + '/api/nature_suivis';
  private suiviURL = Environement.BASE_API_URL + '/api/suivis';
  private suiviNatureURL = Environement.BASE_API_URL + '/api/suivi_natures';
  private clientMailingCalRedURL = Environement.BASE_API_URL + '/api/client_mailing_calendrier_redactionnels';
  private suiviIntranetUrl = Environement.BASE_API_URL + '/suivi/intranet/';
  private calRedUrl = Environement.BASE_API_URL + '/api/calendrier_redactionnels';

  dialogueShareService: DialogueShareService;
  suivi: Suivi;


  constructor(private http: HttpClient) {
  }

  getStatuts() {
    const headers = this.initOptionHeader();
    return this.http.get(this.statutURL, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getStatut(id): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.statutURL + '/' +  id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getVecteur(id): Promise<any>{
    const headers = this.initOptionHeader();
    return this.http.get(this.vecteurURL + '/' +  id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getVecteurs() {
    const headers = this.initOptionHeader();
    return this.http.get(this.vecteurURL, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getNature(id) {
    const headers = this.initOptionHeader();
    return this.http.get(this.natureUrl + '/' + id, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getNatures() {
    const headers = this.initOptionHeader();
    return this.http.get(this.natureUrl, {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }


  addSuivi(suivi, mutuPar) {
    const headers = this.initOptionHeader();
    const DateCre = new Date();
    const DateCreFormat = DateCre.getFullYear() + '-' + (DateCre.getMonth() + 1).toString() + '-' + DateCre.getDate() + ' ' + DateCre.getHours() + ':' + DateCre.getMinutes() + ':00' ;
    const DateFormat = suivi.date.getFullYear() + '-' + (suivi.date.getMonth() + 1).toString() + '-' + suivi.date.getDate() + ' ' + suivi.date.getHours() + ':' + suivi.date.getMinutes() + ':00' ;
    // si suivi pas lié à une mutualisation
    if (mutuPar == 0) {
      return this.http.post(this.suiviURL , {'titre': suivi.titre, 'date': DateFormat, 'commentaires': suivi.commentaires,
          'niveau': suivi.niveau, 'aParaitre': suivi.aParaitre,
          'suiviPar': Environement.BASE_API_URL + '/api/auth_users/' + suivi.suiviPar.id, 'email': suivi.email,
          'telephone': suivi.telephone, 'mutualisation': null,
          'contact': Environement.BASE_API_URL + '/api/contacts/' + suivi.contact.id,
          'typeRelation': Environement.BASE_API_URL + '/api/type_relation_suivis/' + suivi.typeRelation.id,
          'vecteur': Environement.BASE_API_URL + '/api/vecteur_suivis/' + suivi.vecteur.id,
          'statut': Environement.BASE_API_URL + '/api/statut_suivis/' + suivi.statut.id,
          'supports': [], 'auteur': Environement.BASE_API_URL + '/api/auth_users/' + suivi.auteur.id,
          'creation': DateCreFormat, 'inclureRA' : true},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      // si suivi lié à une mutualisation
      return this.http.post(this.suiviURL , {'titre': suivi.titre, 'date': DateFormat, 'commentaires': suivi.commentaires,
          'niveau': suivi.niveau, 'aParaitre': suivi.aParaitre,
          'suiviPar': Environement.BASE_API_URL + '/api/auth_users/' + suivi.suiviPar.id, 'email': suivi.email,
          'telephone': suivi.telephone, 'mutualisation': Environement.BASE_API_URL + '/api/auth_users/' + mutuPar,
          'contact': Environement.BASE_API_URL + '/api/contacts/' + suivi.contact.id,
          'typeRelation': Environement.BASE_API_URL + '/api/type_relation_suivis/' + suivi.typeRelation.id,
          'vecteur': Environement.BASE_API_URL + '/api/vecteur_suivis/' + suivi.vecteur.id,
          'statut': Environement.BASE_API_URL + '/api/statut_suivis/' + suivi.statut.id,
          'supports': [], 'auteur': Environement.BASE_API_URL + '/api/auth_users/' + suivi.auteur.id,
          'creation': DateCreFormat, 'inclureRA' : true},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }


  addSuiviNature(suiviId, natureActionSuiviIDSelect, axe, interesse, sujet, theme, interlocuteur, raison, commentaireVeille, opportunite, date) {
    const headers = this.initOptionHeader();
    const DateFormat = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':00' ;
    if (opportunite == 0) {
      return this.http.post(this.suiviNatureURL , {
          'suivi': Environement.BASE_API_URL + '/api/suivis/' + suiviId,
          'nature': Environement.BASE_API_URL + '/api/nature_suivis/' + natureActionSuiviIDSelect,
          'axe': axe, 'interesse': interesse, 'sujet': sujet,
          'themeItw': theme, 'interlocuteur': interlocuteur, 'raison': raison, 'commentaire': commentaireVeille,
          'dateItw': DateFormat},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.post(this.suiviNatureURL , {
          'suivi': Environement.BASE_API_URL + '/api/suivis/' + suiviId,
          'nature': Environement.BASE_API_URL + '/api/nature_suivis/' + natureActionSuiviIDSelect,
          'axe': axe, 'interesse': interesse, 'sujet': sujet,
          'themeItw': theme, 'interlocuteur': interlocuteur, 'raison': raison, 'commentaire': commentaireVeille,
          'opportunitesClient': Environement.BASE_API_URL + '/api/clients/' + opportunite,
          'dateItw': DateFormat},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  addClientMailingCalRed(societeId, communiqueId, calRedId, article, suiviId) {
    const headers = this.initOptionHeader();
    let communique = null;
    let calRed = null;
    if (communiqueId != 0) {
      communique = Environement.BASE_API_URL + '/api/maillings/' + communiqueId;
    }
    if (calRedId != '' && calRedId != null) {
      calRed = Environement.BASE_API_URL + '/api/calendrier_redactionnels/' + calRedId;
    }
    return this.http.post(this.clientMailingCalRedURL , {
     'suivi': Environement.BASE_API_URL + '/api/suivis/' + suiviId,
     'client': Environement.BASE_API_URL + '/api/clients/' + societeId,
     'mailing': communique,
     'calendrierRedactionnel': calRed,
     'article': article},
    {headers} ).toPromise()
    .then(response => response)
    .catch(this.errorHandler);
}

  addSupportSuivi(suppId, suiviId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'ajouter/support/suivi/' + suiviId + '/' + suppId, {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  importerTout(contactIDSelect, calRedUn, calRedDeux, mediaIdsSelect) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'importation/tout/' + contactIDSelect + '/' + calRedUn + '/' + calRedDeux + '/' + mediaIdsSelect,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
}

  importerSuiviIntranet(suiviId, mediaIdsSelect) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'importation/copie/suivi/' + suiviId + '/' + mediaIdsSelect,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  supprimerImportSuiviIntranet(suiviId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'importation/supprimer/copie/suivi/' + suiviId ,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  verifierRelanceIntranet(user, contactId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'relance/verifier/intranet/' + user + '/' + contactId ,
      {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  getSuivis(page): Promise<any>{
    let headers = this.initOptionHeader();
    return this.http.get(this.suiviURL + '?_page=' + page + '&order[date]=desc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getSuivisParDefaut(page, jour) {
    let headers = this.initOptionHeader();
    return this.http.get(this.suiviURL + '?suivi_defaut=' + jour + '&_page=' + page + '&order[date]=desc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getAllSuivisOrganisation(socId, page) {
    let headers = this.initOptionHeader();
    return this.http.get(this.suiviURL + '?contact.contactOrganisations.organisation.id=' + socId + '&_page=' + page + '&order[date]=desc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getAllSuivisClient(clientId, page) {
    let headers = this.initOptionHeader();
    return this.http.get(this.suiviURL + '?clientMailingCalendrierRedactionnels.client.id=' + clientId + '&_page=' + page + '&order[date]=desc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getSuivisRechercheAvancee(titre, typeId, natureId, vecteurId, statutId, note, contactIDSelect, suiviPar, premiereSocId, deuxiemeSocId, periodeDeb, periodeFin, date, creeChoisi, jours, page) {
    let headers = this.initOptionHeader();
    let url = '';
    // customiser url de recherche en fonction des champs renseignés
    if (titre != '') {
      url = url + '&titre=' + titre ;
    }
    if (typeId != 0) {
      url = url + '&typeRelation.id=' + typeId ;
    }
    if (natureId != 0) {
      url = url + '&suiviNatures.nature.id=' + natureId ;
    }
    if (vecteurId != 0) {
      url = url + '&vecteur.id=' + vecteurId ;
    }
    if (statutId != 0) {
      url = url + '&statut.id=' + statutId ;
    }
    if (note != '') {
      url = url + '&commentaires=' + note ;
    }
    if (contactIDSelect != 0) {
      url = url + '&contact.ancienId=' + contactIDSelect ;
    }
    if (suiviPar != 0) {
      url = url + '&suiviPar.id=' + suiviPar ;
    }
    if (premiereSocId != 0) {
      url = url + '&clientMailingCalendrierRedactionnels.client.id=' + premiereSocId ;
    }
    if (deuxiemeSocId != 0) {
      url = url + '&clientMailingCalendrierRedactionnels.client.id=' + deuxiemeSocId ;
    }
    if (periodeDeb) {
      periodeDeb = periodeDeb.getFullYear() + '-' + (periodeDeb.getMonth() + 1) + '-' + periodeDeb.getDate() + ' 00:00:00' ;
      url = url + '&date[after]=' + periodeDeb ;
    }
    if (periodeFin) {
      periodeFin = periodeFin.getFullYear() + '-' + (periodeFin.getMonth() + 1) + '-' + periodeFin.getDate() + ' 23:59:59' ;
      url = url + '&date[before]=' + periodeFin ;
    }
    if (date) {
      const datedeb = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 00:00:00' ;
      url = url + '&date[after]=' + datedeb ;
      const datefin = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' 23:59:59' ;
      url = url + '&date[before]=' + datefin ;
    }
    if (creeChoisi != '' && jours != 0) {
      const dateActuelle = new Date();
      dateActuelle.setDate(dateActuelle.getDate() - jours );
      const dateActu = dateActuelle.getFullYear() + '-' + (dateActuelle.getMonth() + 1) + '-' + dateActuelle.getDate() + ' '
        + dateActuelle.getHours() + ':' + dateActuelle.getMinutes() + ':00' ;
      if (creeChoisi == 'plus') {
        // il y a plus de X jours
        url = url + '&date[before]=' + dateActu ;
      }
      if (creeChoisi == 'moins') {
        // il y a moins de X jours
        url = url + '&date[after]=' + dateActu ;
      }
    }
    // supprimer le premier &
    url = url.substr(1);
    return this.http.get(this.suiviURL + '?' + url + '&_page=' + page + '&order[date]=desc', {headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getSuivisRecherche(recherche: Array<any>, page) {
    const content = recherche[0] + '~' + recherche[2];
    let type = recherche[1];
    const headers = this.initOptionHeader();
    type = removeAccents(type.toLowerCase());
    if (type === 'tous') {
      return this.http.get(this.suiviURL + '?several_all=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'contact') {
      return this.http.get(this.suiviURL + '?suivi_contact=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'client') {
      return this.http.get(this.suiviURL + '?suivi_client=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'collaborateur') {
      return this.http.get(this.suiviURL + '?suivi_user=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'titre') {
      return this.http.get(this.suiviURL + '?suivi_titre=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
    if (type === 'support') {
      return this.http.get(this.suiviURL + '?suivi_support=' + content + '&_page=' + page + '&order[date]=desc',
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  getSuiviDetails(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviURL + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  getCalRedDetails(id): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(this.calRedUrl + '/' + id , {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerSuiviNature(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.suiviNatureURL + '/' +  id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  recupererParutionSuivi(suiviId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/parutions?suiteSuivi.id=' + suiviId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  suppParutionSuivi(parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/parutions/' +  parutionId, {'suiteSuivi' : null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  recupererParutionInterview(suiviId): Promise<any> {
    const headers = this.initOptionHeader();
    return this.http.get(Environement.BASE_API_URL + '/api/parutions?suiteItw.id=' + suiviId, {headers: headers}).toPromise()
      .then( response => response)
      .catch(this.errorHandler);
  }

  suppParutionInterview(parutionId) {
    const headers = this.initOptionHeader();
    return this.http.put(Environement.BASE_API_URL + '/api/parutions/' +  parutionId, {'suiteItw' : null},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerClientMailingCalRed(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.clientMailingCalRedURL + '/' +  id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerSuivi(id) {
    const headers = this.initOptionHeader();
    return this.http.delete(this.suiviURL + '/' +  id,{headers: headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  inclureRA(suivi) {
    const headers = this.initOptionHeader();
    if (suivi.inclureRA == true) {
      return this.http.put(this.suiviURL + '/' +  suivi.id, {'inclureRA' : false},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.put(this.suiviURL + '/' +  suivi.id, {'inclureRA' : true},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  modifierSuivi(suivi, mutuPar) {
    const headers = this.initOptionHeader();
    // si suivi pas lié à une mutualisation
    if (mutuPar == 0) {
      return this.http.put(this.suiviURL + '/' +  suivi.id, {'titre': suivi.titre, 'commentaires': suivi.commentaires,
          'niveau': suivi.niveau, 'aParaitre': suivi.aParaitre,
          'suiviPar': Environement.BASE_API_URL + '/api/auth_users/' + suivi.suiviPar.id, 'email': suivi.email,
          'telephone': suivi.telephone, 'mutualisation': null,
          'contact': Environement.BASE_API_URL + '/api/contacts/' + suivi.contact.id,
          'typeRelation': Environement.BASE_API_URL + '/api/type_relation_suivis/' + suivi.typeRelation.id,
          'vecteur': Environement.BASE_API_URL + '/api/vecteur_suivis/' + suivi.vecteur.id,
          'statut': Environement.BASE_API_URL + '/api/statut_suivis/' + suivi.statut.id,
          'auteur': Environement.BASE_API_URL + '/api/auth_users/' + suivi.auteur.id,
           'inclureRA' : true},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      // si suivi lié à une mutualisation
      return this.http.put(this.suiviURL + '/' +  suivi.id, {'titre': suivi.titre, 'commentaires': suivi.commentaires,
          'niveau': suivi.niveau, 'aParaitre': suivi.aParaitre,
          'suiviPar': Environement.BASE_API_URL + '/api/auth_users/' + suivi.suiviPar.id, 'email': suivi.email,
          'telephone': suivi.telephone, 'mutualisation': Environement.BASE_API_URL + '/api/auth_users/' + mutuPar,
          'contact': Environement.BASE_API_URL + '/api/contacts/' + suivi.contact.id,
          'typeRelation': Environement.BASE_API_URL + '/api/type_relation_suivis/' + suivi.typeRelation.id,
          'vecteur': Environement.BASE_API_URL + '/api/vecteur_suivis/' + suivi.vecteur.id,
          'statut': Environement.BASE_API_URL + '/api/statut_suivis/' + suivi.statut.id,
          'auteur': Environement.BASE_API_URL + '/api/auth_users/' + suivi.auteur.id,
          'inclureRA' : true},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  modifierSuiviNature(suiviNatureId, suiviId, natureActionSuiviIDSelect, axe, interesse, sujet, theme, interlocuteur, raison, commentaireVeille, opportunite, date) {
    const headers = this.initOptionHeader();
    const dateNewFormat = new Date(date);
    const DateFormat = dateNewFormat.getFullYear() + '-' + (dateNewFormat.getMonth() + 1) + '-' + dateNewFormat.getDate() + ' ' + dateNewFormat.getHours() + ':' + dateNewFormat.getMinutes() + ':00' ;
    if (opportunite == 0 ) {
      return this.http.put(this.suiviNatureURL + '/' +  suiviNatureId, {
          'nature': Environement.BASE_API_URL + '/api/nature_suivis/' + natureActionSuiviIDSelect,
          'axe': axe, 'interesse': interesse, 'sujet': sujet,
          'themeItw': theme, 'interlocuteur': interlocuteur, 'raison': raison, 'commentaire': commentaireVeille,
          'dateItw': DateFormat},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    } else {
      return this.http.put(this.suiviNatureURL + '/' +  suiviNatureId, {
          'nature': Environement.BASE_API_URL + '/api/nature_suivis/' + natureActionSuiviIDSelect,
          'axe': axe, 'interesse': interesse, 'sujet': sujet,
          'themeItw': theme, 'interlocuteur': interlocuteur, 'raison': raison, 'commentaire': commentaireVeille,
          'opportunitesClient': Environement.BASE_API_URL + '/api/clients/' + opportunite,
          'dateItw': DateFormat},
        {headers} ).toPromise()
        .then(response => response)
        .catch(this.errorHandler);
    }
  }

  modifierClientMailingCalRed(clientMailClaRedId, societeId, communiqueId, calRedId, article, suiviId) {
    const headers = this.initOptionHeader();
    let communique = null;
    let calRed = null;
    if (communiqueId != 0) {
      communique = Environement.BASE_API_URL + '/api/maillings/' + communiqueId;
    }
    if (calRedId != '' && calRedId != null) {
      calRed = Environement.BASE_API_URL + '/api/calendrier_redactionnels/' + calRedId;
    }
    return this.http.put(this.clientMailingCalRedURL + '/' +  clientMailClaRedId, {
        'client': Environement.BASE_API_URL + '/api/clients/' + societeId,
        'mailing': communique,
        'calendrierRedactionnel': calRed,
        'article': article},
      {headers} ).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  supprimerSupportSuivi(suppId, suiviId) {
    const headers = this.initOptionHeader();
    return this.http.get(this.suiviIntranetUrl + 'supprimer/support/suivi/' + suiviId + '/' + suppId, {headers: headers}).toPromise()
      .then(response => response)
      .catch(this.errorHandler);
  }

  initNouveauSuivi(id) {
    this.suivi = new Suivi(id, 0, new Date(), '', '', null, null,
      null, null, null, null, null, null,
      null, null, null, [], [], [], null, new Date(), new Date());

  }

  private errorHandler(err: any): Promise<any> {
    return Promise.reject(err.message || err );
  }

  private initOptionHeader(){
    const token: any = localStorage.getItem('auth-tokens');
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    headers = headers.set('X-Auth-Token', token);
    return headers;}
}
