import { Component, OnInit } from '@angular/core';
import {Parution} from '../../../../model/parution/Parution';
import {ParutionService} from '../../../../service/interne/parution/parutionService';
import {ActivatedRoute} from '@angular/router';
import {FormatIntranetShareService} from '../../../../service/partage/prototype/FormatIntranetShareService';

@Component({
  selector: 'app-escal-parution-fiche',
  templateUrl: './escal-parution-fiche.component.html',
  styleUrls: ['./escal-parution-fiche.component.scss'],
  providers: [ ParutionService ]
})
export class EscalParutionFicheComponent implements OnInit {

  private sub: any;
  parution: Parution;
  active = 'informations';
  constructor(private parutionService: ParutionService, private route: ActivatedRoute,
              public formatIntranetShareService: FormatIntranetShareService) { }

  ngOnInit() {
    const that = this;
    this.sub = this.route.params.subscribe(params => {
      this.parutionService.getParutionDetails(params.id)
        .then((parution) => {
          that.parution = parution as Parution;
        });
    });
  }

  refresh() {
    this.ngOnInit();
  }

  actived(type) {
    if (type === this.active) {
      return 'active';
    }
  }

}
